import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    spanish: 'Spanish',
    english: 'English',
    backoffice: {
      virtualTerminal: {
        acordeon1: {
          title: 'Payment/Authorization Information',
          subtitle:
            'Accept Payment Method American Express, Discover Mastercard',
          cardNumber: 'Card Number',
          expirationDate: 'Expiration Date',
          amount: 'Amount'
        },
        acordeon2: {
          title: 'Order Information',
          subscriptionName: 'Subscription Name',
          invoice: 'Invoice #',
          description: 'Description',
          placeholder: 'Enter more information here...'
        },
        acordeon3: {
          title: 'Subscription Interval',
          subtitle: 'Select how often customer service billed.',
          selectOption: 'Select Option',
          interval1: 'Monthly',
          interval2: 'Quarterly',
          interval3: 'Semi-annual',
          interval4: 'Annualy'
        },
        acordeon4: {
          title: 'Subscription Duration',
          startDate: 'Start Date',
          noEnd: 'No End Date (Ongoing subscription)',
          endDate: 'End Date',
          endAfter: 'End After'
        },
        acordeon5: {
          title: 'Payment',
          nameAcount: 'Name on acount',
          paymentType: 'Payment Type',
          creditCard: 'Credit Card',
          subscriptionName: 'Subscription Name',
          trialAmount: 'Trial Amount',
          firstName: 'First Name',
          sameAsBilling: 'Same As Billing'
        }
      },
      worksheetStatus: {
        draft: 'Draft',
        opened: 'Opened',
        closed: 'Closed',
        worksheetDetail: 'Worksheet Details',
        fields: {
          responsable: 'Responsible',
          startDate: 'Start Date',
          endDate: 'End Date',
          workers: 'Workers'
        },
        tooltip: {
          staffWorker: 'Add staff worker',
          resetAllCalendar: 'Reset all calendar',
          showCalendarDetails: 'Show calendar details',
          resetCalendar: 'Reset this calendar',
          removeStaffWorker: 'Remove staff worker'
        },
        message: {
          totalHour: 'Total hours worker:',
          hours: 'hours',
          with: 'with',
          minutes: 'minutes',
          typeMessage: 'Type message Here...'
        },
        button: {
          saveDraft: 'Save Draft',
          saveOpen: 'Save and Open',
          close: 'Close',
          addShift: 'Add Shift',
          changeworksheetRequest: 'Change Request'
        }
      },
      emailTest: {
        sendTest: 'Send Test Email',
        message:
          "This is a sample message. Please, don't respond this message.",
        subject: 'iMenuApps® support.',
        success: 'Email was sent successfully.'
      },
      menu: {
        validationPhone: 'The phone number must contain exactly 10 digits.',
        savedSuccesful: 'The setting was changed successfully',
        savedFailed: 'There was an error saved settings.',
        lostChanges: 'Your changes will be lost if you leave this page!',
        sure: 'Are you sure?',
        merchantQuoteNotes: 'Merchant Quotes Notes',
        merchantInvoiceNotes: 'Merchant Invoice Notes',
        titleInformationIncomplete: 'Required information',
        informationIncomplete: 'Please complete all required fields.',
        yes: 'Yes',
        emailSend: 'E-mail sent',
        emailSendTo: 'The email with the invoice has been sent correctly to',
        questionSendEmail: 'Send an e-mail?',
        dialogSendEmail: 'Are you sure you want to send the email to',
        addNewSendEmail: 'Add another email address',
        send: 'Send',
        close: 'Close',
        alertEmailSend: 'Email sent',
        alertEmailSendTo: 'Successfully sent to',
        session: 'Session',
        foodServices: 'Products/Services',
        ordersTickets: 'Orders/Tickets',
        reservationsDeliveries: 'Reservations/Deliveries',
        restaurantAdministration: 'Administration',
        report: 'Reports',
        sales: 'Sales',
        staffOccupationsCustomersDrivers: 'Staff/Occupations/Customers/Drivers',
        marketing: 'Marketing',
        settings: 'Settings',
        merchantSettings: 'Merchant Settings'
      },
      controlPanel: {
        subscription:
          'View and Create Subscriptions and Billing (Virtual Terminal)',
        staff: 'Creation of user accounts for business workers.',
        occupations:
          'Management of business occupations. Occupations are related to staff members. Each member has a specific occupation.',
        roles:
          'Role management. The roles establish the permissions on the different areas of the application and are related to the staff members. A staff member can have multiple roles.',
        workSheet:
          'Work Sheet. Work sheet allow the control over staff working time.',
        customers: 'Displays a list of the business customers.',
        categories:
          'Manage the different categories of the menus. Categories are related to items. A item belongs to a category.',
        menuItems:
          'Management of items. A menu is made up of categories, and in turn, the items are related to the menu through the category to which it belongs.',
        services:
          'Management of services. The services are used in the catering menus.',
        menus:
          'Menu management. Allows menu management. A menu is made up of various categories.',
        orders: 'View and Create Orders',
        invoices: 'View and Create Invoices',
        quotes: 'View and Create Quotes',
        cateringOrders: 'Allows the display of catering orders.',
        orderForDelivery:
          'Displays a list of orders that are ready to be delivered to the driver who has been assigned the order.',
        tickets:
          'Allows the creation and tracking of tickets. Tickets are orders that remain open while the customer continues to consume.',
        otherCharges:
          'Allows the management of additional charges for quantity of products.',
        taxes: 'Tax management.',
        deliveries: 'Management of delivery prices.',
        reservations: 'Manage and Create Reservations and Waitlist',
        tables: 'Management of business capacities.',
        payments: 'Shows the list of payment transactions made.',
        aboutSettings:
          'It allows the management of the slider that appears on the home page of each business.',
        paymentSettings:
          'It allows the configuration of the payment gateway that will be used to process transactions.',
        basicSettings:
          'It allows the configuration of the main data of the business: Name, Address, Telephone... This information appears on the home page of each business.',
        funtionalitiesSettings:
          'It allows the configuration of the general operation of the business: Hours, Zip codes, tips...',
        colorSettings:
          'It allows the configuration of the colors of the application.',
        systemSettings:
          'Allows the configuration of iMenuApps® integration with third-party applications.',
        driver: 'Management of external drivers',
        credit: 'Allow credits administrations.'
      },
      options: {
        billingAddress: 'Billing Address',
        shippingAddress: 'Shipping Address',
        placeholderNotes: 'Write your notes here',
        dataRequired: 'Data Required',
        customerArea: 'Customer Area',
        customerName: 'Customer Name',
        customerEmail: 'Customer Email',
        customerPhone: 'Customer Phone',
        customerAddress: 'Customer Address',
        terms: 'Terms',
        trackingN: 'Tracking N°',
        shippingDate: 'Shipping Date',
        shippingTo: 'Shipping To',
        ivoiceDate: 'Invoice Date',
        quoteDate: 'Quote Date',
        subscriptionBilling: 'Subscription Billing',
        driverDeliveries: 'Deliveries',
        activeDriverOrder: 'Active Orders',
        completedDriverOrder: 'Completed Orders',
        useDirection: 'Click if same as billing address',
        menuTitle: 'ADMIN',
        login: 'Login',
        logout: 'Logout',
        controlPanel: 'Management',
        supportPanel: 'Support',
        manageMenus: 'Menus',
        managePyments: 'Payments',
        manageHouseAccount: 'House Account',
        viewOrders: 'View/Create Orders',
        viewQuotes: 'Quotes',
        viewInvoice: 'Invoices',
        manageCategories: 'Categories',
        manageProducts: 'Items',
        manageServices: 'Services',
        manageCustomers: 'Customers',
        manageCredits: 'Credits',
        manageTables: 'Tables',
        manageTaxes: 'Taxes',
        manageShippings: 'Deliveries',
        manageOtherCharges: 'Other Charges',
        manageUsers: 'Staff',
        manageDriver: 'Drivers',
        manageRoles: 'Roles/Permissions',
        manageWorkSheet: 'Worksheet',
        manageBasicSettings: 'Business',
        manageRestaurantSettings: 'Business Settings',
        manageAboutSettings: 'About Us Slides',
        manageFunSettings: 'Functionalities',
        manageColourSettings: 'Colours',
        manageDesign: 'Design',
        manageMore: 'More',
        managePaymentSettings: 'Payments',
        manageOccupation: 'Occupations',
        manageKeySettings: 'External Systems',
        supportSettings: 'Support Settings',
        changeServerId: 'Change Employee ID',
        serverId: 'Employee Code',
        manageVariantGroup: 'Choices',
        manageReservation: 'Reservations',
        manageWaitList: 'Wait List',
        viewCateringOrders: 'Catering Orders',
        forgotPassword: 'Did you forget your password?',
        sendNewPassword: 'Send a new password',
        recoveryPassword: 'Recovery password',
        refund: 'Refund',
        void: 'Void'
      },
      titles: {
        bankAccountName: 'Bank account name',
        routeNumber: 'Route number',
        accountNumber: 'Account number',
        bankAccountAddress: 'Bank account address',
        nameAuthorizedPerson: 'Name of authorized person',
        paymentAmount: 'Payment amount',
        mustBe: 'Must be 9 digits',
        warning: 'Warning',
        messageChangeQuote:
          'If you change this Prefix again, your sequence numbering of your quotes will change',
        messageChangeIvoice:
          'If you change this Prefix again, your sequence numbering of your invoices will change',
        messageStartingInvoiceNumber:
          'If you will change this starting number, your numbering for the invoices will be reset to start from this number.',
        prefixQuotes: 'Prefix for Quotes (e.g. QTE)',
        prefixInvoices: 'Prefix for Invoices (e.g. INV)',
        invoiceStartingNumber: 'Invoice Starting Number',
        moneyNotEnought: 'Amount must be greater than the total to get change',
        controlPanel: 'System Management',
        supportPanel: 'Support',
        menus: 'Menus',
        orders: 'Orders',
        quotes: 'Quotes',
        invoices: 'Invoices',
        allOrders: 'All Orders',
        customerCredit: 'Customer Credit',
        workSheet: 'Work Sheet',
        cateringOrders: 'Catering Orders',
        ordersForDelivery: 'Orders for Delivery',
        suscriptors: 'Subscribers',
        tickets: 'Order Tickets',
        categories: 'Categories',
        products: 'Items',
        customers: 'Customers',
        tables: 'Tables',
        taxes: 'Taxes',
        shippings: 'Delivery Prices',
        otherCharges: 'Other Charges',
        newsletter: 'Newsletter',
        users: 'Staff',
        suscriptor: 'Subscribers',
        occupation: 'Occupation',
        roles: 'Roles',
        about: 'About Us Slides',
        digitalScreens: 'Digital screens',
        editAboutForm: 'Edit About Us Slide',
        newAboutForm: 'Create a New About Us Slide',
        editDisplayForm: 'Edit Digital Signage Slide',
        newDisplayForm: 'Create a Digital Signage Slide',
        basicConfig: 'Basic Conf.',
        functions: 'Functionalities',
        colors: 'Colors',
        variantGroup: 'Choices',
        loading: 'Please wait...',
        editColorSetting: 'Edit Color',
        createColorSetting: 'Create Color ',
        orderCateringForm: 'Edit Catering Order',
        deliveryLocation: 'Delivery location',
        cateringMenus: 'Catering Menus',
        payments: 'Payments',
        ticketDetails: 'Ticket Details',
        emptyResult: 'Empty result',
        cancellationType: 'Cancellation type',
        changePassword: 'Change password',
        restaurantName: 'Business name',
        clockinClockout: 'Clock In - Clock Out',
        clockin: 'Clock In',
        clockout: 'Clock Out',
        timecard: 'Timecard',
        timesheet: 'Timesheet',
        addContact: 'Add Contact',
        updateContact: 'Update Contact',
        contacts: 'Contacts',
        staff: 'Staff'
      },
      placeholder: {
        contactNotFound: 'Contact Not found'
      },
      orderState: {
        started: 'started',
        inKitchen: 'in kitchen',
        cooked: 'cooked',
        delivering: 'pending delivery',
        delivered: 'delivered'
      },
      list: {
        buttons: {
          goToControlPanel: 'Go to management'
        },
        actions: {
          addANew: 'Add a new',
          edit: 'Edit',
          delete: 'Delete',
          view: 'View'
        },
        entitiesName: {
          menu: 'menu',
          category: 'category',
          categoryPlaceholder: 'Select category',
          product: 'item',
          customer: 'customer',
          table: 'table',
          tax: 'tax',
          taxPlaceholder: 'Select tax',
          shipping: 'delivery',
          otherCharge: 'other charge',
          user: 'user',
          order: 'order',
          role: 'role',
          about: 'about'
        },
        messages: {
          confirmDelete: 'Confirm delete',
          confirmAccept: 'Confirm item',
          confirmProduct: 'Do you want to accept this item?',
          deleteCategory: 'Do you want to delete this category?',
          deleteRestaurant: 'Do you want to delete this business?',
          deleteOrder: 'Do you want to delete this order?',
          deleteCredit: 'Do you want to delete this credit?',
          deleteWorkSheet: 'Do you want to delete this work sheet?',
          deleteCustomer: 'Do you want to delete this customer?',
          deleteMenu: 'Do you want to delete this menu?',
          deleteOtherCharge: 'Do you want to delete this other charge?',
          deleteNewsletter: 'Do you want to delete this newsletter?',
          deleteProduct: 'Do you want to delete this item?',
          deleteShipping: 'Do you want to delete this delivery?',
          deleteTable: 'Do you want to delete this table?',
          deleteTax: 'Do you want to delete this tax?',
          deleteUser: 'Do you want to delete this user?',
          deleteSuscriptor: 'Do you want to delete this subscriber?',
          deleteRole: 'Do you want to delete this role?',
          deleteAbout: 'Do you want to delete this information?',
          deleteDisplay: 'Do you want to remove this ad?',
          deleteOccupation: 'Do you want to delete this occupation?',
          deleteVariantGroup: 'Do you want to delete this choices?',
          infoDeleteSuccess: 'Info',
          messageDeleteSuccessCategory: 'Category was successfully deleted',
          messageDeleteSuccessRestaurant: 'Business was successfully deleted',
          messageDeleteSuccessOrder: 'Order was successfully deleted',
          messageDeleteSuccessCredit: 'Credit was successfully deleted',
          messageDeleteSuccessWorkSheet: 'Work sheet was successfully deleted',
          messageDeleteSuccessCustomer: 'Customer was successfully deleted',
          messageDeleteSuccessMenu: 'Menu was successfully deleted',
          messageDeleteSuccessOtherCharges:
            'Other charges was successfully deleted',
          messageDeleteSuccessNewsletter: 'Newsletter was successfully deleted',
          messageDeleteSuccessProduct: 'Item was successfully deleted',
          messageDeleteSuccessShipping: 'Shipping was successfully deleted',
          messageDeleteSuccessTable: 'Table was successfully deleted',
          messageDeleteSuccessTax: 'Tax was successfully deleted',
          messageDeleteSuccessUser: 'User was successfully deleted',
          messageDeleteSuccessSuscriptor: 'Subscriber was successfully deleted',

          messageActivareSuccessSuscriptor:
            'Subscriber was successfully activated',
          messageDesactivareSuccessSuscriptor:
            'Subscriber was successfully deactivated',

          messageDeleteSuccessRole: 'Role was successfully deleted',
          messageDeleteSuccessAbout: 'Information was successfully deleted',
          messageDeleteSuccessOccupation: 'Occupation was successfully deleted',
          messageDeleteSuccessVariantGroup: 'Choices was successfully deleted',
          messageCateringApproved:
            'The catering order was successfully approved',
          messageCateringCancelled:
            'The catering order was successfully canceled',
          titleEditCategory: 'Edit category',
          titleEditCustomer: 'Edit customer',
          titleEditMenu: 'Edit menu',
          titleEditOtherCharges: 'Edit other charge',
          titleEditProduct: 'Edit item',
          titleEditShipping: 'Edit delivery',
          titleEditTable: 'Edit table',
          titleEditTax: 'Edit tax',
          titleEditUser: 'Edit user',
          titleEditRole: 'Edit role',
          titleEditSetting: 'Edit setting',
          titleEditOccupation: 'Edit occupation',
          titleCreateCategory: 'Create category',
          titleCreateCustomer: 'Create customer',
          titleCreateMenu: 'Create menu',
          titleCreateOtherCharges: 'Create other charge',
          titleCreateProduct: 'Create item',
          titleCreateShipping: 'Create delivery',
          titleCreateTable: 'Create table',
          titleCreateTax: 'Create tax',
          titleCreateUser: 'Create user',
          titleCreateRole: 'Create role',
          titleCreateSetting: 'Create setting',
          titleCreateOccupation: 'Create occupation',
          titleEditVariantGroup: 'Edit choices',
          titleCreateVariantGroup: 'Create a new choices',
          messageEditSuccessCategory: 'The category was edited successfully',
          messageEditSuccessCredit: 'The credit was edited successfully',
          messageEditSuccessCustomer: 'The customer was edited successfully',
          messageEditSuccessMenu: 'The menu was edited successfully',
          messageEditSuccessWorksheet: 'The worksheet was edited successfully',
          messageEditSuccessOtherCharges:
            'The other charge was edited successfully',
          messageEditSuccessProduct: 'The item was edited successfully',
          messageEditSuccessShipping: 'The delivery was edited successfully',
          messageEditSuccessTable: 'The table was edited successfully',
          messageEditSuccessTax: 'The tax was edited successfully',
          messageEditSuccessUser: 'The user was edited successfully',

          messageEditSuccessSuscriptor:
            'The subscriber was edited successfully',
          messageEditSuccesRole: 'The role was edited successfully',
          messageEditSuccessSetting: 'The settings was edited successfully',
          messageEditSuccessOccupation:
            'The occupation was edited successfully',
          messageEditSuccessVariantGroup: 'The choices was edited successfully',
          messageDeleteSuccessVariant: 'The choices was deleted successfully',
          messageEditSuccessVariant: 'The choices was edited successfully',
          messageCreateSuccessVariant: 'The choices was created successfully',
          messageCreateSuccessCategory: 'The category was created successfully',
          messageCreateSuccessCredit: 'The credit was created successfully',
          messageCreateSuccessCustomer: 'The customer was created successfully',
          messageCreateSuccessMenu: 'The menu was created successfully',
          messageCreateSuccessWorksheet:
            'The worksheet was created successfully',
          messageCreateSuccessOtherCharges:
            'The other charge was created successfully',
          messageCreateSuccessProduct: 'The item was created successfully',
          messageCreateSuccessShipping: 'The delivery was created successfully',
          messageCreateSuccessTable: 'The table was created successfully',
          messageCreateSuccessTax: 'The tax was created successfully',
          messageCreateSuccessUser: 'The user was created successfully',
          messageCreateSuccessSuscriptor:
            'The subscriber was created successfully',
          messageCreateSuccessRole: 'The role was created successfully',
          messageCreateSuccessSetting: 'The setting was created successfully',
          messageCreateSuccessOccupation:
            'The occupation was created successfully',
          messageCreateSuccessVariantGroup:
            'The choices was created successfully',
          menuChangeState: 'Menu state was successfully changed',
          activeMenu: 'Active menu',
          otherChargeChangeState: 'Other charge state was successfully changed',
          activeOtherCharge: 'Active other charge',
          taxChangeState: 'Tax state was successfully changed',
          activeTax: 'Active tax',
          shippingChangeState: 'Shipping state was successfully change',
          activeShipping: 'Active delivery',
          deleteSubtitleCategory: 'Delete category',
          deleteSubtitleCustomer: 'Delete customer',
          deleteSubtitleMenu: 'Delete menu',
          deleteSubtitleOtherCharges: 'Delete other charges',
          deleteSubtitleProduct: 'Delete item',
          deleteSubtitleShipping: 'Delete delivery',
          deleteSubtitleTable: 'Delete table',
          deleteSubtitleTax: 'Delete tax',
          deleteSubtitleUser: 'Delete user',
          deleteSubtitleRole: 'Delete role',
          deleteSubtitleAbout: 'Delete information',
          deleteSubtitleOccupation: 'Delete occupation',
          deleteSubtitleVariantGroup: 'Delete choices',
          deleteAertMessage: 'Are you sure you want to delete the contact.',
          errorTitle: 'Error',
          errorMessage: 'Something went wrong.',
          changeOrderStateMessage: 'Change order state',
          changeOrderStateSuccess: 'Order state was changed successfully',
          changeOrderStateCanceled: 'Order was canceled',
          connectionError: 'Connection error',
          sharedWorksheet: 'Shared worksheet with the Staff member',
          connectionErrorMessage:
            'There was an error connection. Please, verify your internet connection and retry. If the problem persist, contact your service provider.',
          newPasswordSent: 'The new password was sent to your email',
          emailOrPasswordIncorrect: 'Email or password incorrect',
          buttons: {
            cancel: 'Cancel',
            delete: 'Delete',
            close: 'Close',
            goToAdministration: 'Go to administration',
            goToList: 'Go to list',
            retry: 'Retry'
          }
        }
      },
      form: {
        login: 'Login',
        colour: {
          primaryColor: 'Primary color',
          primary: 'Primary',
          primaryText: 'Primary text',
          secondaryColor: 'Secondary color',
          secondary: 'Secondary',
          secondaryText: 'Secondary text',
          tertiaryColor: 'Tertiary color',
          tertiary: 'Tertiary',
          tertiaryText: 'Tertiary text',
          successColor: 'Success color',
          success: 'Success',
          successText: 'Success text',
          dangerColor: 'Danger color',
          danger: 'Danger',
          dangerText: 'Danger text',
          backgroundColor: 'Background color',
          background: 'Background'
        },
        tableShape: {
          square: 'Square',
          rectangular: 'Rectangular',
          circle: 'Circle',
          oval: 'Oval'
        },
        buttons: {
          backToCategoriesList: 'Back to categories list',
          backToCustomersList: 'Back to customers list',
          backToMenusList: 'Back to menus list',
          backToOrdersList: 'Back to orders list',
          backToOtherChargesList: 'Back to others charges list',
          backToProductsList: 'Back to item list',
          backToShippingsList: 'Back to deliveries list',
          backToTablesList: 'Back to tables list',
          backToTaxesList: 'Back to taxes list',
          backToUsersList: 'Back to users list',
          backToRolesList: 'Back to roles list',
          backToAboutList: 'Back to about list',
          backToMenu: 'Back to menu',
          cancelOrder: 'Cancel order',
          changeState: 'Change state to',
          defaultColor: 'Default ',
          addChoice: 'Add Variant',
          back: 'Back',
          save: 'Save',
          submit: 'Submit',
          change: 'Change',
          add: 'Add',
          update: 'Update'
        },
        titles: {
          invoiceReminderLabel:
            'Enter every how many days a reminder will be sent',
          invoiceReminder: 'Turn-On Invoice Reminders',
          assetPickupRequest: 'Asset Pickup Request',
          pickupAddress: 'Pickup Address',
          requireDeposit: 'Require Deposit',
          lateFees: 'Turn-On Late Fees Invoice',
          lateFeesLabel: 'Enter the percentage of arrears to collect',
          thisInvoice: 'This Invoice has been PAID',
          customerNewTitle: 'Create a new customer',
          customerEditTitle: 'Edit customer',
          categoryNewTitle: 'Create a new category',
          creditNewTitle: 'Create a new credit',
          categoryEditTitle: 'Edit category',
          creditEditTitle: 'Edit credit',
          menuNewTitle: 'Create a new menu',
          workSheetNewTitle: 'Create Worksheet',
          menuEditTitle: 'Edit menu',
          workSheetEditTitle: 'Edit Worksheet',
          productNewTitle: 'Create an item',
          serviceNewTitle: 'Create a service',
          productEditTitle: 'Edit item',
          serviceEditTitle: 'Edit service',
          changePaymentSetting: 'Change payment settings',
          shippingNewTitle: 'Create a new delivery price',
          shippingEditTitle: 'Edit delivery price',
          taxNewTitle: 'Create a new tax',
          taxEditTitle: 'Edit tax',
          tableNewTitle: 'Create a new table',
          tableEditTitle: 'Edit table',
          packageNewTitle: 'Create a new package',
          packageEditTitleditTitle: 'Edit package',
          variantGroupEditTitle: 'Edit choices',
          variantGroupNewTitle: 'Create a new choice',
          otherChargeNewTitle: 'Create a new other charge',
          otherChargeEditTitle: 'Edit other charge',
          userNewTitle: 'Create a new staff member',

          suscriptionNewTitle: 'Create a new subscription',

          driversNewTitle: 'Create a new driver',
          userEditTitle: 'Edit staff member',

          suscriptionEditTitle: 'Edit subscription',
          driversEditTitle: 'Edit driver',
          supportUsers: 'Support users',
          occupationNewTitle: 'Create New Occupation/Job',
          occupationEditTitle: 'Edit Occupation/Job',
          roleNewTitle: 'Add Permissions',
          roleEditTitle: 'Edit role',
          selectAProduct: 'Select a item',
          selectACategory: 'Select a category',
          selectACustomer: 'Select a customer',
          selectATax: 'Select a tax',
          selectARestaurant: 'Select a business',
          selectAState: 'Select a state',
          filterCategory: 'Filter...',
          selectALanguage: 'Language',
          selectAnOccupation: 'Select an occupation',
          selectACooker: 'Select a cook',
          selectADriver: 'Select a driver',
          allProducts: 'All items:',
          settingEditTitle: 'Edit settings',
          settingNewTitle: 'Add settings',
          generalSettingEditTitle: 'Edit basic settings',
          generalSettingNewTitle: 'Add basic settings',
          keySettingEditTitle: 'Edit system settings',
          keySettingNewTitle: 'Create system settings',
          funSettingEditTitle: 'Functionalities Settings',
          funSettingNewTitle: 'Create functionalities settings',
          pickFromSelect: 'Select pick from',
          pickToSelect: 'Select pick to',
          dateFromSelect: 'Select date from',
          dateToSelect: 'Select date to',
          cancelOrder: 'Cancel order',
          drivers: 'Drivers',
          customer: 'Customer',
          date: 'Date',
          startDate: 'Start Date',
          endDate: 'End Date',
          orderType: 'Order type',
          addressToDelivery: 'Address for delivery',
          hourToPick: 'Hour to pick-up',
          tableService: 'Table service',
          products: 'Items',
          services: 'Services',
          otherCharges: 'Other charges',
          shippingPrice: 'Shipping price',
          subtotal: 'Subtotal',
          tax: 'Tax total',
          tip: 'Tip',
          total: 'Total',
          pendingPay: 'Pending Payment',
          payed: 'Paid',
          notes: 'Notes',
          cancelNotes: 'Cancel notes',
          pickupNotes: 'Pickup Notes',
          cooker: 'Cook',
          state: 'State',
          generalConfiguration: 'GENERALS CONFIGURATIONS',
          paymentConfiguration: 'PAYMENT CONFIGURATIONS',
          cashDiscount: 'CASH DISCOUNT',
          eposConfiguration: 'EPOS CONFIGURATION',
          emailConfiguration: 'EMAIL CONFIGURATIONS',
          twilioSMS: 'Twilio SMS',
          sinchSMS: 'Sinch SMS',
          selectAVariantGroup: 'Select a choice',
          imageText:
            'For better results please upload image size 750x750px. Make certain that you have rights to any image uploaded',
          orderCateringForm: 'Approval of catering order',
          restaurantHours: 'Business hours',
          deadlinesNotes:
            'Please, ensure that the sum of the deadlines is 100.',
          selectDate: 'Select date',
          toPay: 'To pay',
          pending: 'Pending',
          genaral: 'General',
          others: 'Others',
          variants: 'Variants',
          sides: 'Sides',
          ingredients: 'Ingredients',
          shift4: 'Shift4',
          auth: 'Authorize.Net',
          capcha: 'Captcha',
          zipCodes: 'Zip codes',
          backups: 'Backups',
          devices: 'Devices',
          creditSetting: 'Credit Setting'
        },
        fields: {
          changeImage: 'Change Image',
          addImage: 'Add Image',
          customerHours: 'Customer Hours',
          to: 'To',
          subject: 'Subject',
          message: 'Message',
          name: 'Name',
          menuName: 'Menu name',
          description: 'Description',
          email: 'Email',
          password: 'Password',
          date: 'Date',
          image: 'Image',
          phone: 'Phone',
          productMin: 'Item min',
          productMax: 'Item max',
          price: 'Price',
          extraCharge: 'Extra Charge',
          costPrice: 'Cost',
          retailPrice: 'Sale',
          stimateTime: 'Estimated time (in minutes)',
          stockQuantity: 'Stock Quantity',
          minReorderPoint: 'Lower Stock Quantity',
          maxReorderPoint: 'Max Stock Quantity',
          CreditAmount: 'Credit Amount',
          vendorName: 'Vendor Name',
          vendorCode: 'Vendor Code',
          shortDescription: 'Short Description',
          keywords: 'Keywords',
          tags: 'Tags',
          binNumber: 'Bin Number',
          addSerialNumber: 'Add Serial Number',
          active: 'Active',
          save: 'Save',
          submitToken: 'Submit Token',
          available: 'Available',
          availableCategories: 'AVAILABLE CATEGORIES',
          availableProducts: 'AVAILABLE ITEMS',
          availableStaff: 'AVAILABLE STAFF',
          selectedProducts: 'SELECTED ITEMS',
          selectedCategories: 'SELECTED CATEGORIES',
          selectedStaff: 'SELECTED STAFF',
          workedHours: 'WORKED HOURS',
          scheduledDates: 'Scheduled Dates',
          selectedAggregates: 'SELECTED AGGREGATES',
          marketingConsent: 'MARKETING CONSENT',
          product: 'Item',
          category: 'Category',
          tax: 'Tax',
          parentCategory: 'Parent category',
          qrCode: 'QR Code',
          capacity: 'Capacity',
          percent: 'Percentage',
          priority: 'Priority (0 - 1)',
          permissions: 'Permissions',
          permissionsRole: 'Permission Roles',
          selectCashDiscount: 'Select Cash Discount',
          roles: 'Occupation/Job',
          address: 'Address',
          title: 'Title',
          subtitle: 'Subtitle',
          online: 'Online',
          showOtherRestaurant: 'Show others business',
          reservationByTable: 'Book tables',
          reservationByStaff: 'Book staff',
          tablesChoose: 'Client can choose location',
          onlineMss: 'Business is not visible to the public ',
          onlineMss1: 'Business is visible to the public ',
          mustClockinMss: 'You must clock-in first. Please, ',
          mustClockin2Mss: 'clock in.',
          SelectPickHour: 'Select pick-up hour',
          SelectPickHour2: 'Activate Pick-Up Time',
          TipRequire: 'Tip require',
          tips: 'Tips',
          assetPickup: 'Asset Pickup',
          depositAmount: 'Deposit Amount',
          typeTip: 'Type a percent tip',
          MinTimeToCook: 'Min time to cook',
          PickFrom: 'Pick from',
          PickFromToolTip: 'Add the time delivery starts',
          PickTo: 'Pick to',
          PickToToolTip: 'Add the time delivery ends',
          DateFrom: 'Date from',
          DateTo: 'Date to',
          DateLimit: 'Limit date to pay',
          ShowCooker: 'Show cook',
          zipCodes: 'Zip codes',
          deliveryZone: 'Delivery zones',
          cooker: 'Cook',
          driver: 'Driver',
          rejectedOrder: 'Order Rejected By Driver',
          cancelationNote: 'Cancellation note',
          tableNumber: 'Table number',
          firstName: 'First name',
          lastName: 'Last name',
          occupation: 'Occupation',
          confirmPassword: 'Confirm password',
          endPointUrl: 'Endpoint Url',
          capchaKey: 'Captcha key',
          clientGuid: 'Client GUID',
          interfazVersion: 'Interface version',
          interfazName: 'Interface name',
          companyName: 'Company name',
          apiLoginId: 'API login Id',
          eposToken: 'ePos token',
          epos: 'Integrate with ePos',
          transactionKey: 'Transaction Key',
          smtpHost: 'SMTP Host',
          port: 'Port',
          secure: 'Secure',
          twFromNumber: 'From number',
          twAccountSID: 'Account SID',
          twToken: 'Token',
          sinchFromNumber: 'From number',
          sinchAccountSID: 'Service plan id',
          sinchToken: 'Token',
          web: 'Website',
          variantGroup: 'Choices',
          haveSides: 'Sides',
          cantOfSides: 'Number of sides included',
          categoryOfSides: 'Filter sides for category',
          addIngredients: 'Specify ingredients',
          typeAnIngredient: 'Type an ingredient',
          canViewGeoposition: 'Can view Geolocation',
          canViewRating: 'Can view rating',
          viewDelivery: 'Can create delivery orders',
          viewOnTable: 'Can create table orders',
          viewCurbside: 'Can create curbside orders',
          tablePrefix: 'Table prefix',
          barPrefix: 'Bar prefix',
          roomPrefix: 'Room prefix',
          typeZipCode: 'Type a zipCode',
          isCatering: 'Is catering',
          isService: 'Service menu',
          isReservationMenu: 'Reservation menu',
          catering: 'Catering',
          minAmoutCatering: 'Min amount for catering',
          minAmountCateringDelivery: 'Min amount for delivery',
          loyaltyMinPointToBuy: 'Min points to buy',
          loyaltyExpirationMonths: 'Loyalty expiration number of months',
          loyaltyPointMoney: 'Number of points for $1',
          partialPay: 'Partial pay',
          payForQuotation: 'Pre-pay for quotation',
          percentPayForQuotation: 'Pre-payment amount for quotation',
          service: 'Service',
          showServicePrice: 'Show service price',
          viewCatering: 'View catering',
          viewLoyalty: 'View loyalty program',
          viewReservation: 'View reservation',
          reservation: 'Reservation',
          loyalty: 'Loyalty Program',
          minDayToReservation: 'Minimum number of days to reserve',
          viewCustomerReservation: 'View customers reservation',
          sameHourForAllDays: 'Same hour for all days',
          followingDays: 'Same hours for the following days',
          timezone: 'Timezone',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          allDays: 'All days',
          marginDays: 'Minimum number of days to place order',
          cateringStates: 'Catering states',
          typeState: 'Type an state code',
          zipCodesExclude: 'Zip Codes included',
          cateringEvents: 'Catering event',
          typeEventName: 'Type an event name',
          proposeNewDataTime: 'Propose a new date/time',
          fixed: 'Fixed amount',
          amount: 'Amount',
          discount: 'Discount',
          deadlines: 'Deadlines',
          pendingPay: 'Pending payment',
          payed: 'Paid',
          states: 'States',
          paymentMethod: 'Payment Method',
          cash: 'Cash',
          card: 'Card',
          check: 'Check',
          free: 'Free',
          busy: 'Busy',
          dirty: 'Dirty',
          authenticationToken: 'Authorization token',
          authenticationTokenMoto: 'MOTO Authorization token',
          authenticationTokenFB: 'F&B Authorization token',
          accountType: 'Account Type',
          method: 'Method',
          serverName: 'Server name',
          urlLocation: 'Url location',
          fax: 'Fax',
          oldServerId: 'Old Employee ID',
          newServerId: 'New Employee ID',
          acivateTwilio: 'Activate Twilio',
          acivateSinch: 'Activate Sinch',
          isDriver: 'Is a Driver',
          monthy: 'Monthly',
          anual: 'Annual',
          merchantOnboardingApplication: 'Merchant Onboarding Application',
          applicationName: 'Application Name',
          agentId: 'Agent ID',
          planId: 'Plan ID',
          dateOfBirth: 'Date Of Birth',
          equityOwnershipPercent: 'Equity Ownership Percentage',
          isPersonalGuarantor: 'Is Personal Guarantor?',
          bookablePackage: 'Is bookable package',
          showProgressBar: 'Show Progress Bar To Driver'
        },
        messages: {
          buttons: {
            ok: 'Ok',
            dismiss: 'Dismiss'
          },
          activatePaymentMethod: 'has been activated.',
          incorrectToken: 'The token sent is not correct.',
          activationMethodError: 'The activation method returned an error.',
          unexpectedError: 'An unexpected error has occurred.',
          savePaymentMethodSuccess:
            'The payment method settings have been saved.',
          savePaymentMethodError:
            'An error occurred while saving the payment method settings.'
        },
        validate: {
          validate: 'Validate',
          validateCategory: 'Validate category',
          validateCustomer: 'Validate customer',
          validateMenu: 'Validate menu',
          validateOtherCharge: 'Validate other charge',
          validateProduct: 'Validate item',
          validateShipping: 'Validate delivery',
          validateTable: 'Validate table',
          validateTax: 'Validate tax',
          validateUser: 'Validate user',
          validateRole: 'Validate role',
          validateSetting: 'Validate setting',
          validateOccupation: 'Validate occupation',
          validateVariantGroup: 'Validate choices',
          name: 'Name is required',
          image: 'Image is required',
          email: 'Email is required',
          phone: 'Phone is required',
          emailIncorrect: 'Email is incorrect',
          date: 'Date is required',
          selectedCategories: 'Selected categories is required',
          productMinNumber: 'Item min must be a number',
          productMaxNumber: 'Item max must be a number',
          productMinGreater: 'Item min greater than 0',
          productMaxGreater: 'Item max greater than 2',
          price: 'Price must be a number',
          priceGreater: 'Price must be greater than 0',
          product: 'You must select a item',
          costPriceNumber: 'Cost price must be a number',
          retailPriceNumber: 'Sale price must be a number',
          retailPriceShouldBeGreaterThanSalePrice:
            'Retail Price should always be greater than the Sale Price',
          costPriceGreater: 'Cost price must be greater than 0',
          retailPriceGreater: 'Sale price must be greater than 0',
          category: 'You must select a category',
          capacity: 'Capacity must be a number',
          capacityGreater: 'Capacity must be greater than 0',
          percent: 'Percent must be a number',
          percentGreater: 'Percent must be greater than 0',
          password: 'Password is required',
          address: 'Address is required',
          title: 'Title is required',
          subtitle: 'Subtitle is required',
          zipCode: 'The zip code is not a valid zip code',
          duplicateZipCode: 'Duplicate zip code',
          cateringPriceZero: 'The catering price is zero',
          cooker: 'You must fill cook field',
          tableNumber: 'Table number must be a number',
          tableNumberGreater: 'Table number must be greater than 0',
          nameDuplicate:
            'There is a table with this name. You must change the number or the type.',
          firstName: 'You must fill first name field',
          lastName: 'You must fill last name field',
          confirmPassword:
            'The password and confirm password fields must be equal',
          occupation: 'You must fill the occupation field',
          notes: 'You must fill cancellation note field',
          tip: 'The tip cannot be zero',
          tipRepeat: 'The tip exist. Please, type a new number.',
          emailDontExist: 'Email does not exist.',
          ingredient: 'Ingredient already exists.'
        },
        permissionsGroup: {
          permissionMenu: 'MENU PERMISSION',
          permissionCategory: 'CATEGORY PERMISSION',
          permissionProduct: 'ITEM PERMISSIONS',
          permissionSpecialPrice: 'SPECIAL PRICE PERMISSIONS',
          permissionCustomer: 'CUSTOMER PERMISSIONS',
          permissionCredit: 'CREDIT PERMISSIONS',
          permissionHouseAccount: 'HOUSE ACCOUNT PERMISSIONS',
          permissionTable: 'TABLE PERMISSIONS',
          permissionTax: 'TAX PERMISSIONS',
          permissionShipping: 'DELIVERY PERMISSIONS',
          permissionOtherCharge: 'OTHER CHARGE PERMISSIONS',
          permissionUser: 'STAFF PERMISSIONS',
          permissionDriver: 'DRIVER SUPERVISION PERMISSIONS',
          permissionDeliveryDriver: 'DELIVERY DRIVER PERMISSIONS',
          permissionOrder: 'ORDER PERMISSIONS',
          permissionRole: 'ROLE PERMISSIONS',
          permissionWorkSheet: 'WORKSHEET PERMISSIONS',
          permissionSetting: 'SETTING PERMISSIONS',
          permissionPayment: 'PAYMENT PERMISSIONS',
          permissionReport: 'REPORTS PERMISSIONS',
          permissionClockin: 'CLOCK-IN PERMISSIONS',
          permissionOccupation: 'OCCUPATION PERMISSIONS',
          permissionVariantGroup: 'CHOICES PERMISSIONS',
          permissionReservation: 'RESERVATION PERMISSIONS',
          permissionSuscriptors: 'SUBSCRIBERS PERMISSIONS',
          permissionSuscription: 'SUBSCRIPTION PERMISSIONS',
          canViewMenu: 'Can view menus',
          canCreateMenu: 'Can create menus',
          canEditMenu: 'Can edit menus',
          canDeleteMenu: 'Can delete menus',
          canViewCategory: 'Can view categories',
          canCreateCategory: 'Can create categories',
          canEditCategory: 'Can edit categories',
          canDeleteCategory: 'Can delete categories',
          canViewProduct: 'Can view item',
          canCreateProduct: 'Can create item',
          canEditProduct: 'Can edit items',
          canDeleteProduct: 'Can delete items',
          canViewSpecialPrices: 'Can view special price',
          canCreateSpecialPrices: 'Can create special price',
          canEditSpecialPrices: 'Can edit special price',
          canDeleteSpecialPrices: 'Can delete special price',
          canViewCustomer: 'Can view customers',
          canCreateCustomer: 'Can create customers',
          canEditCustomer: 'Can edit customers',
          canDeleteCustomer: 'Can delete customers',
          canViewCredit: 'Can view credits',
          canCreateCredit: 'Can create credits',
          canEditCredit: 'Can edit credits',
          canDeleteCredit: 'Can delete credits',
          canViewHouseAccount: 'Can view house account',
          canCreateHouseAccount: 'Can create house account',
          canCreateSuscriptor: 'Can create subscribers',
          canViewSuscriptor: 'Can view subscribers',
          canEditSuscriptor: 'Can edit subscribers',
          canDesactivateSuscriptor: 'Can deactivate subscribers',
          canViewTable: 'Can view tables',
          canCreateTable: 'Can create tables',
          canEditTable: 'Can edit tables',
          canDeleteTable: 'Can delete tables',
          canViewTax: 'Can view taxes',
          canCreateTax: 'Can create taxes',
          canEditTax: 'Can edit taxes',
          canDeleteTax: 'Can delete taxes',
          canViewShipping: 'Can view shippings',
          canCreateShipping: 'Can create deliveries',
          canEditShipping: 'Can edit deliveries',
          canDeleteShipping: 'Can delete deliveries',
          canViewOtherCharge: 'Can view other charges',
          canCreateOtherCharge: 'Can create other charges',
          canEditOtherCharge: 'Can edit other charges',
          canDeleteOtherCharge: 'Can delete other charges',
          canViewUser: 'Can view users',
          canCreateUser: 'Can create users',
          canEditUser: 'Can edit users',
          canDeleteUser: 'Can delete users',
          canViewDriver: 'Can view external drivers',
          canCreateDriver: 'Can create external drivers',
          canEditDriver: 'Can edit external drivers',
          canDeleteDriver: 'Can delete external drivers',
          canViewDeliveryDriver: 'Can View Delivery Driver',
          canEditDeliveryStatus: 'Can Edit Delivery Status',
          canCreateDeliveryDriver: 'Can Create Delivery Driver',
          canDeleteDeliveryDriver: 'Can Delete Delivery Driver',
          canViewOccupation: 'Can view occupation',
          canCreateOccupation: 'Can create occupation',
          canEditOccupation: 'Can edit occupation',
          canDeleteOccupation: 'Can delete occupation',
          canViewRole: 'Can view roles',
          canCreateRole: 'Can create roles',
          canEditRole: 'Can edit roles',
          canDeleteRole: 'Can delete roles',
          canViewWorkSheet: 'Can view worksheet',
          canCreateWorkSheet: 'Can create worksheet',
          canEditWorkSheet: 'Can edit worksheet',
          canDeleteWorkSheet: 'Can delete worksheet',
          canViewOrder: 'Can view orders',
          canEditOrder: 'Can edit orders',
          canCreateOrder: 'Can create orders',
          canViewOrderForDelivery: 'Can view orders for delivery',
          canChangeSetting: 'Can change settings',
          canViewVariantGroup: 'Can view choices',
          canCreateVariantGroup: 'Can create choices',
          canEditVariantGroup: 'Can edit choices',
          canDeleteVariantGroup: 'Can delete choices',
          canViewReservation: 'Can view reservation',
          canCreateReservation: 'Can create reservation',
          canEditReservation: 'Can edit reservation',
          canDeleteReservation: 'Can delete reservation',
          canViewPayment: 'Can view payments',
          canIgnoreClockin: 'Can ignore clock in',
          canViewSalesByCustomer: 'Can view Sales By Customer',
          canViewSalesByProduct: 'Can view Sales By Product',
          canViewDynamicReports: 'Can view Dynamic Reports',
          canViewSubscription: 'Can view Subscription',
          canCreateSubscription: 'Can Create Subscription',
          canEditSubscription: 'Can Edit Subscription',
          canDeleteSubscription: 'Can delete Subscription'
        },
        marketingMessages: {
          orderInfo: 'Order information',
          inKitchen:
            ', Hello! Thank you for using our service. Your order is right now in our kitchen.',
          cooked: 'Your order is ready. It looks great!',
          delivering:
            'Your order is on its way right now. Just a few minutes, please',
          delivered: 'Your order has been delivered. Bon Appétit!',
          canceled: 'Your order has been canceled',
          cateringApproved:
            ', Your catering order has been approved. Please, check your email for order details.',
          cateringCancel: ', sorry, your catering order has been canceled.',
          reservationChangeStatus: 'Reservation change status',
          reservationApproved:
            ', Hello! Your reservation has been approved for ',
          reservationApproved2: ', you need confirm the reservation now.',
          reservationProposedDateTime:
            ', Hello! We regret that we cannot meet the date/time requested by you. We propose a new date/time: ',
          reservationRejected:
            ', Hi! We are sorry to have to decline your reservation due to lack of capacity.',
          reservationCancel:
            'Your reservation has been canceled. If you have any questions, do not hesitate to contact us.'
        }
      }
    },
    frontend: {
      menu: {
        clockInMessage: 'You have successfully clocked in',
        signIn: 'Sign In',
        chatImenu: 'iMenuApps® Support',
        aboutImenu: 'About iMenuapps®',
        seeLess: 'See less',
        seeMore: 'See more',
        foodAndMoodJournal: 'Food and Mood Journal',
        motivation: `To view your order history, download the iMenuApps® application and register.
             <a href="https://apps.apple.com/us/app/imenuapps/id1574771438" target="_blank">Download on the Apple App Store</a> or 
             <a href="https://play.google.com/store/apps/details?id=net.imenuapps.apps&hl=en_US&pli=1" target="_blank">Get it on Google Play</a>.`,
        motivationMobile:
          'To view your order history, register with iMenuApps®.',
        menu: 'Menu',
        shop: 'Shop',
        ads: 'Digital Signage',
        credit: 'Credits',
        menuCatering: 'Catering Menu',
        pickupRequest: 'Pickup Request',
        reservation: 'Reservations',
        about: 'About Us',
        chat: 'Chat',
        eMarkeeting: 'eMarketing',
        contact: 'Contact Us',
        oferts: 'Offer',
        init: 'Home',
        loginMerchant: 'Merchant Login',
        loginClient: 'Client Login',
        editProfile: 'Edit your profile',
        edit: 'Edit your data',
        log: 'Sign In / Register',
        catering: 'Catering Service',
        logout: 'Logout',
        logIn: 'Login',
        profil: 'My Account',
        profil1: 'Profile',
        createUser: 'Register',
        forgotPassword: 'Did you forget your password?',
        passwordReset: 'Password Reset',
        newPassword: 'New Password',
        newData: 'Modify your Data',
        confirmPassword: 'Confirm Password',
        register: 'Register',
        orders: 'Orders',
        restaurant: 'Click below to select Business Type',
        restablacerPassword: 'Reset Password',
        updateData: 'Update Data',
        mssForgotPassword: 'We have sent a new password to your email.',
        errorLogIn: 'Incorrect email or password',
        errEmailExist: 'This email already exists',
        errPassNotCoinc: 'It does not match the new password',
        userNotFound: 'Incorrect email',
        refresh: 'Refresh',
        mssUpdateData: 'Your data was updated correctly',
        mssCreateUser:
          'Customer created successfully, we have sent your new password via e-mail.',
        mssPasswordChangedCorrectly: 'Successfully updated your password',
        ticketMenu: 'Open Ticket'
      },
      app: {
        allBusiness: 'All Businesses',
        hospitality: 'Hospitality',
        reservations: 'Reservations',
        service: 'Service',
        entertainment: 'Entertainment',
        retail: 'Retail',
        fullText:
          'Introducing iMenuApps®: Your Comprehensive Business Solution with Enhanced Client Portal iMenuApps® presents an all-encompassing platform designed to fulfill every business need, revolutionizing the way you operate and connect with your customers. Our application not only empowers businesses to streamline operations and boost sales but also incorporates a cutting-edge client portal that enhances the customer experience. With our client portal, customers can effortlessly re-order products and services and maintain a comprehensive record of their transactions and profiles with each merchant they engage with on our platform. This feature fosters convenience, efficiency, and personalized interactions, ultimately strengthening the bond between businesses and their valued clients. Whether you operate in Hospitality, Retail, Service, or Entertainment, iMenuApps® is the ultimate solution tailored to your industry-specific requirements. Our advanced platform seamlessly integrates the client portal, enabling businesses to provide a seamless and personalized experience to their clientele. Embrace iMenuApps® and unlock a world of possibilities for your business. Benefit from our intuitive user interface, robust functionality, and secure infrastructure, all geared towards optimizing operations and fostering long-lasting customer relationships.',
        thisPay: 'This Payment Made',
        thisPayDone: 'This payment has already been made.',
        selectOrder: 'Select Your Order Type',
        selectDetail: 'Your data information',
        deliver: 'Delivery',
        pickup: 'Pick-Up',
        table: 'Table',
        curbside: 'Curbside',
        notClient:
          'There are no orders associated with this client. Remember that only registered customers can view their orders.',
        getOrder: 'To get orders',
        editTitle: 'Edit your Data',
        notPermissionForRating: 'Only registered users can rate a business',
        thanksForRating: 'Thanks for your rating',
        sessionExpired:
          'Your session has expired due to inactivity. Please log in again.',
        notRestaurantFound:
          'The requested business does not exist. We hope you find another one of your preference in our list.',
        all: 'All',
        productsAndServices: 'Sales',
        reservation: 'Bookings'
      },
      product: {
        title: 'Items of',
        back: 'Back',
        noProducts: "Oops! Sorry, this category doesn't have items yet. ",
        productDetail: 'Item Detail',
        massageToast: 'Successful ',
        productNote: 'Enter your item note here...',
        productWithVariant: 'Click ADD for other choices'
      },
      home: {
        country: 'Country',
        stateProvince: 'State/Province/Region',
        county: 'County',
        autocomplete:
          'Start entering your address here so it can auto populate',
        selectCategory: 'Menu categories',
        cardTitle: 'Cart details',
        cardheader: 'Items',
        cancel: 'Cancel',
        acept: 'Ok',
        confirm: 'Confirm',
        clear: 'Clear',
        checkout: 'Check Out',
        deliverDetail: 'Delivery Details',
        address: 'Address',
        pickupDetail: 'Pick-Up Details',
        curbsideDetail: 'Curbside Details',
        cateringDate: 'Catering Date',
        pickTime:
          'Pick-Up time not available. Please choose another pick-up time',
        tableQr: 'Location QR',
        notValidQr: 'Not a valid QR',
        notSeatAvailable: 'This seat is already assigned',
        errorRequired: 'Insert required information',
        errorDateNotValid: 'Date not valid',
        noProductForReorder:
          'There is no availability of any item for re-order.',
        noProductAvailable:
          'The following items do not appear in the order, because they are not available today.',
        clientRequired: 'Action allowed only for registered users. Log in.',
        cantNotValid: 'The item quantity is not valid',
        zipCodeNotValid: 'Zip Code not Valid.',
        notZoneToDeliver: 'We currently do not deliver in this area.',
        state: 'State',
        city: 'City',
        notValidEmail: 'Not a Valid Email',
        reqSuccess: 'Successfully Submitted the Change Request!',
        approveSuccess: 'Successfully Approved the Change Request!',
        alertDeleteData: 'Do you want to start over?',
        notTimeToPick: 'Pick-Up hours are between: ',
        cookTime: '. The estimated time for cooking is ',
        priceEstimated: 'Estimated Price',
        minuts: ' Minutes.',
        selectOrderType: 'Please choose your order type.',
        search: 'Search',
        aggregateFree: 'Included Free',
        sides: 'Sides',
        choises: 'Ingredients',
        variants: 'Variants',
        withoutIngredients: 'Without ingredients',
        eventType: 'Event Type',
        emailSended: 'Email sent',
        choseView: 'Optional Views',
        applicationCreateSuccess: 'Application Created Successfully',
        applicationCreateError: 'Error occurred while creating application'
      },
      orderType: {
        order: 'Order',
        invoice: 'Invoice',
        quote: 'Quote',
        email: 'Email',
        password: 'Password',
        emailPhone: 'Email and Phone',
        name: 'First Name and Last Name',
        phone: 'Phone',
        address: 'Address',
        code: 'Zip Code',
        pickDate: 'Pick-Up Date',
        pickOut: 'Pick-Up Time',
        licencePlate: 'License Plate',
        vehicleModel: 'Vehicle Make/Model',
        vehicleColor: 'Vehicle Color',
        contactedBy: 'Contact via',
        tellContact: 'Tell us how to best contact you',
        worker: 'Employee',
        minAmountDelivery: 'Catering minimum amount',
        minAmountPickUp: 'Pick Up Catering minimum amount'
      },
      reservation: {
        availability: 'Weekly reservation time',
        reservations: 'Reservations',
        reservationDetail: 'Reservation Detail',
        create: 'New Reservation',
        reservationDate: 'Reservation Date',
        reservationHour: 'Reservation Hour',
        timezone: 'Timezone',
        peoples: 'People',
        serviceTime: 'Estimate Service Time',
        reason: 'Occasion',
        find: 'Search for a Reservation',
        enterCode: 'Enter the code',
        yoursReservations: 'Reservations',
        code: 'Code',
        completeDateReservacion: 'Date',
        notcodefind: 'No reservation found associated with this code',
        state0: 'Request',
        state1: 'Approved',
        state2: 'Proposed Date / Time',
        state3: 'Rejected',
        state4: 'Confirm',
        state5: 'Cancel',
        state6: 'Close',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        Sunday: 'Sunday',
        all: 'All',
        restaurantHour: 'Business hours',
        open: 'Open',
        close: 'Close Now, Continue to Page',
        errorHourCaterin: 'Catering time for ',
        errorHour: 'Reservation time for ',
        errorHour2: ' is from ',
        discount: 'Discount',
        orderCloseToDiscount:
          "This Reservation is closed, and you can't use this Quotation Payment.",
        subtotalNotForDiscount:
          "You can't use this Quotation Payment because is bigger than Subtotal.",
        notDiscountToApply: 'No discount to apply',
        restaurantNotes: 'Business Note',
        quotationMessage1: 'Our prepaid dinner rate is  ',
        quotationMessage2:
          'and this amount will be deducted from your order if you place an order with us. ',
        dinnerPrePayment: 'Prepaid dinner',
        timeToReserve: 'Duration in minutes ',
        available: 'Availability',
        notAvailable: 'No Availability',
        deposit: 'Reservation Deposit',
        seatsAvailable: ' Seats Available',
        guestLocation: 'Select the location of all your guests',
        notGuestAvaibility: 'There is no availability for all your guests',
        selectStaff: 'Select a staff'
      },
      order: {
        item: 'Item',
        description: 'Description',
        quantity: 'Qty',
        taxable: 'Taxable?',
        amount: 'Amount',
        tip: 'Tip',
        discount: 'Discount',
        creditCardFee: 'Credit Card Fee',
        shipping: 'Shipping',
        tax: 'Tax',
        deposit: 'Deposit',
        pendingAcceptance: 'Pending Acceptance',
        accepted: 'Accepted',
        prepare: 'Prepared',
        email: 'E-mail',
        phone: 'Phone',
        goBack: 'Go Back',
        orderDetail: 'Order Details',
        quoteDetail: 'Quote Details',
        convertInvoice: 'Convert To Invoice',
        aceptQuote: 'Accept Quote',
        productServicesUpdated: 'Products/Services updated',
        payInvoive: 'Pay Invoice',
        invoiceDetail: 'Invoice Details',
        invoiceNumber: 'Invoice No.',
        invoiceTerm: 'Terms',
        invoicePayed: 'Your invoice has been paid',
        shippingDate: 'Shipping Date',
        shippingVia: 'Shipping Via',
        shippingTo: 'Shipping To',
        shippingFrom: 'Shipping From',
        shippingTrackingNumber: 'Tracking Number',
        client: 'Client',
        cart: 'Cart',
        productsOrdered: 'Products Ordered',
        products: 'Items',
        name: 'Name',
        cant: 'Qty',
        price: 'Price',
        total: 'Total',
        otherCharges: 'Other Charges',
        quotationPayment: 'Quotation payment',
        cashPayment: 'Cash Payment',
        isTicket: 'Open order as ticket',
        payAsTicket: 'Authorize payment ',
        sendInvoice: 'Send invoice ',
        saveAsTicket: 'Save Ticket',
        checkPayment: 'Check Payment',
        parcialPayment: 'Partial Payment',
        quotationMessage1: 'Our fee for quoting is ',
        quotationMessage2:
          'and this amount will be deducted from your order if you order from us. ',
        pendingPayment: 'Pending payment',
        subtotal: 'Subtotal',
        taxe: 'Taxes',
        deliver: 'Delivery',
        tip: 'Tip',
        notes: 'Notes',
        payment: 'Payment Detail',
        ccard: 'Credit Card',
        ccode: 'Card Code',
        expcard: 'Expiration Date',
        dueDate: 'Due Date',
        pay: 'Pay',
        verify: 'Verify Payment',
        transictionTitle: 'Transaction status',
        transictionOk: 'Your transaction was successful',
        sendRequest: 'Send request',
        requestCatering:
          'Request sent. The business manager will contact you shortly.',
        transictionError: 'Error executing payment',
        notValidCC: 'Invalid credit card number',
        yourOrders: 'Orders ',
        yourTicket: 'Tickets ',
        seeAll: 'See All Orders',
        orderState: ' Order State',
        cooker: ' Cook',
        type: 'Type',
        state: 'State',
        messageToast:
          "Simply register to track your orders and view your order history. It's that easy!",
        messgForContact:
          "Simply register to track your orders and view your order history. It's that easy!",
        messgForContactCatering: 'You must login to create Catering order',
        date: 'Date',
        state0: 'Pending payment',
        state1: 'Paid',
        state2: 'In Kitchen',
        state3: 'Prepared',
        state4: 'On the way',
        state5: 'Delivered',
        state6: 'Cancelled',
        state7: 'Pending Review',
        state8: 'Catering Approved',
        state9: 'Quote',
        state10: 'Order Picked Up',
        state11: 'Delayed',
        notOrder: 'You have no pending orders',
        transId: 'Transaction',
        invoice: 'Invoice',
        invoiceProposal: 'Invoice Proposal',
        invoiceProposalUpdated: 'Invoice Proposal Updated',
        add: 'Add',
        share: 'Share',
        remove: 'Remove',
        edit: 'Edit',
        cancelReason: 'Reason for cancellation',
        paymentMethod: 'Payment Method',
        orderFor: 'Order for',
        other: 'Other',
        payed: 'Paid',
        toPay: 'To Pay',
        readyForPick:
          ' You will receive a text or email when your order is ready for pickup.',
        location: 'Location',
        hourToPickEstimated: 'Estimated Pick-Up Time',
        dateToPickEstimated: 'Estimated Pick-Up Date',
        routingNumber: 'Bank Routing Numbers',
        accountNumber: 'Account number',
        bankName: 'Name of the bank',
        badroutingNumber: 'Incorrect bank routing number',
        eventName: 'Event Name',
        guessNumber: 'Number of Guests',
        eventDate: 'Event Date',
        eventStartHour: 'Event Start Time',
        eventEndHour: 'Event End Time',
        warningProductsTheOrderToCatering:
          'You are trying to add an item that is NOT from the Catering Service. If you want to continue, you will lose the items previously added. ',
        warningProductsTheCateringToOrder:
          'You are trying to add a Catering Service item. If you want to continue, you will lose the items you added previously. ',
        clientInfo: 'Client information',
        orderType: 'Order Type',
        paymentByCard: 'Pay by Credit Card',
        totalForPay: 'Total to pay',
        firstName: 'First name',
        lastName: 'Last name',
        postalCode: 'Postal Code',
        addressLine1: 'Address',
        updateTicket: 'Update Ticket',
        closeTicket: 'Close Ticket',
        closed: 'Closed',
        requiredCustomer: 'Client Data',
        requiredOrderType: 'Order Type',
        culumnNumber: 'Number of columns in the row'
      },
      tooltips: {
        addOrder: 'Add',
        printOrder: 'Print',
        forward: 'Forward',
        reOrder: 'Reorder',
        refreshOrder: 'Refresh',
        refreshCaptcha: 'Refresh Captcha',
        deleteOrder: 'Remove',
        readyProduct: 'Ready',
        printRes: 'Print',
        shareLocation: 'Share Location',
        closeTicket: 'Close',
        editTicket: 'Edit',
        loadInfo: 'Loading your data',
        loadRestaurant: 'Loading...',
        getToRestaurant: 'Click Here When You Get To business location',
        carInRestaurant: 'The car arrived at the business',
        carArrivedInfo:
          'Thank you for letting us know that your car has arrived',
        errorRestaurantOffline: 'This business is not visible to the public',
        deliveryPayment: 'Delivery Payment'
      },
      payment: {
        splitPayment: 'Split Payment',
        splitIn: 'Divide into',
        splitMss:
          'The amounts to be paid must cover the Total amount of the Order. No payment can be 0 ',
        splitPay: 'Payment #',
        splitToPay: 'Pay',
        splitPayed: 'Paid',
        tjtPayment: 'Card',
        creditPayment: 'Credit',
        devicePayment: 'Device',
        idtechPayment: 'Moto/Swipe',
        swipePayment: 'Swipe',
        cashPayment: 'Cash',
        hcaPayment: 'ACH',
        housePayment: 'Home Account',
        checkPayment: 'Check',
        sharePayment: 'Share Pay',
        qrPayment: 'QR Payment',
        verifyMss:
          'After knowing that your payment has been made, Verify the payment to save the order, or Cancel to not continue this payment.',
        doingPayment: 'Paying',
        authorizingPayment: 'Authorizing payment',
        insuficientFunds: 'Insufficient funds',
        quoteExpired: 'Sorry. The time for this proposal has expired',
        quoteChanged: 'The proposal has been modified, it is in status',
        quoteAcepted: 'The proposal has been accepted',
        goodPayment: 'Paid',
        shareText1:
          ' shared a payment link for the food order placed in our business ',
        shareText1a:
          ' shared a payment link for the reservation made at our business  ',
        shareText2:
          '. Please follow the link below to make the payment.  Thank you!'
      },
      createNew: {
        businessTemplate: 'Business template',
        restaurantName: 'Business name',
        restaurantAddres: 'Business address',
        restaurantPhone: 'Business phone',
        restaurantEmail: 'Business email',
        restaurantZipcode: 'Business zipcode',
        restaurantWeb: 'Business website',
        restaurantFax: 'Business fax',
        merchantName: 'Merchant first name',
        merchantLastName: "Merchant's last name",
        merchantAddres: 'Merchant address',
        merchantZipcode: 'ZipCode',
        merchantPhone: 'Merchant phone number',
        merchantEmail: 'Email of the merchant',
        merchantClerk: 'User Pin (1 - 99999)',
        createTitle: 'Create new business in iMenuApps®',
        dataRestaurant: 'Business details',
        dataMercahnt: 'Merchant data',
        restaurants: 'Businesss',
        createdMessage:
          'The business was created successfully. You will receive an email with the password to access the application ',
        clerkToLong: 'Pin value exceeded, maximum allowed 99999',
        clerkNotValid: 'Not a valid Pin',
        reciveSubscription: 'You want to receive notifications from',
        activateSubscription: 'You want to activate notifications for',
        saving: 'Saving business',
        recordSaving: 'Saving',
        active: 'Active',
        inactive: 'Not active',
        restaurantType: 'Business Type',
        template: 'Template',
        chooseBusinessTemplate: 'Choose business template',
        select: 'Select',
        temrsAndCondition: 'Terms and Conditions',
        trialDate: '14-day trial',
        trialMss: 'Try for 14 days',
        business: 'Business',
        reservation: 'Reservation'
      },
      unsubscribe: {
        title: 'Unsubscribe',
        email: 'Email',
        unsubscribeOk: 'Your email has been unsubscribed successfully',
        unsubscribeFail: 'Error. Mail not found ',
        restaurantFail: 'Business not found'
      },
      specialsPrice: {
        titles: 'Special Prices',
        title: 'Special Price',
        name: 'Name',
        type: 'Type',
        amount: 'Amount',
        for: 'To',
        apply: 'Apply',
        active: 'Active',
        repite: 'Repeat',
        oneStarDate: 'Start Date and Time',
        oneEndDate: 'End Date and Time',
        weekStarDate: 'Start Time',
        weekEndDate: 'End Time',
        monthStardDat: 'Day of the month',
        annualStartDat: 'Month and Day of the year',
        activeRule:
          'Only one rule at a time will be applied. The first one activated will take precedence.'
      },
      credit: {
        state0: 'Requested',
        state1: 'Accepted',
        state2: 'Cancelled',
        state3: 'Close',
        yourCredit: 'Your credits',
        amount: 'Credit amount',
        debt: 'Debt',
        createDate: 'Creation date',
        active: 'Active',
        dateFrom: 'Start date',
        dateTo: 'End Date',
        paymentDate: 'Payment date',
        state: 'State',
        pendingPay: 'Pending payment',
        payed: 'Paid',
        creditpayed: 'Credit Paid',
        mssPay1: 'The customer ',
        mssPay2: 'have made a credit payment ',
        mssPay3: 'for an amount '
      }
    }
  },
  es: {
    spanish: 'Español',
    english: 'Inglés',
    backoffice: {
      virtualTerminal: {
        acordeon1: {
          title: 'Información de pago/autorización',
          subtitle:
            'Aceptar Método de pago American Express, Discover Mastercard',
          cardNumber: 'Numero de tarjeta',
          expirationDate: 'Fecha de expiración',
          amount: 'Monto'
        },
        acordeon2: {
          title: 'Información del pedido',
          subscriptionName: 'Nombre de Suscripción',
          invoice: 'Factura #',
          description: 'Descripción',
          placeholder: 'Escribe más información aqui...'
        },
        acordeon3: {
          title: 'Intervalo de suscripción',
          subtitle:
            'Selecciona la frecuencia con la que factura el servicio de atención al cliente.',
          selectOption: 'Seleccionar opción',
          interval1: 'Mensual',
          interval2: 'Trimestral',
          interval3: 'Semi anual',
          interval4: 'Anualmente'
        },
        acordeon4: {
          title: 'Duración de la suscripción',
          startDate: 'Fecha de inicio',
          noEnd: 'Sin fecha de finalización (suscripción en curso)',
          endDate: 'Fecha final',
          endAfter: 'Terminar después'
        },
        acordeon5: {
          title: 'Pago',
          nameAcount: 'Nombre de la cuenta',
          paymentType: 'Tipo de pago',
          creditCard: 'Tarjeta de credito',
          subscriptionName: 'Nombre de suscripción',
          trialAmount: 'Cantidad de prueba',
          firstName: 'Primer Nombre',
          sameAsBilling: 'Igual que la facturación'
        }
      },
      worksheetStatus: {
        draft: 'Borrador',
        opened: 'Abierta',
        closed: 'Cerrada',
        worksheetDetail: 'Detalles de la hoja de trabajo',
        fields: {
          responsable: 'Responsable',
          startDate: 'Fecha de Inicio',
          endDate: 'Fecha de Fin',
          workers: 'Trabajadores'
        },
        tooltip: {
          staffWorker: 'Añadir trabajadores',
          resetAllCalendar: 'Reiniciar todos los calendarios',
          showCalendarDetails: 'Mostrar detalles del calendario',
          resetCalendar: 'Reiniciar calendario',
          removeStaffWorker: 'Remove staff worker'
        },
        message: {
          totalHour: 'Total de horas trabajadas:',
          hours: 'horas',
          with: 'con',
          minutes: 'minutos'
        },
        button: {
          saveDraft: 'Guardar Borrador',
          saveOpen: 'Guardar y Abrir',
          close: 'Cerrar',
          addShift: 'Agregar turno',
          changeworksheetRequest: 'Solicitud de cambio'
        }
      },
      emailTest: {
        sendTest: 'Enviar Email de Prueba',
        message:
          'Este es un mensaje de ejemplo. Por favor, no responda este mensaje.',
        subject: 'Soporte de iMenuApps®.',
        success: 'El email fue enviado satisfactoriamente.'
      },
      menu: {
        validationPhone:
          'El número de teléfono debe contener exactamente 10 dígitos.',
        savedSuccesful: 'La configuración se cambió con éxito',
        savedFailed: 'Hubo un error en la configuración guardada.',
        lostChanges: '¡Tus cambios se perderán si sales de esta página!',
        sure: '¿Está seguro?',
        merchantQuoteNotes: 'Notas de cotización del comerciante',
        merchantInvoiceNotes: 'Notas de la factura del comerciante',
        titleInformationIncomplete: 'Información requerida',
        informationIncomplete:
          'Por favor completa todos los campos requeridos.',
        yes: 'Si',
        emailSend: 'Email enviado',
        emailSendTo: 'El email con la factura ha sido enviado correctamente a',
        questionSendEmail: '¿Enviar correo electrónico?',
        dialogSendEmail:
          '¿Está seguro que desea enviar el correo electrónico a',
        addNewSendEmail: 'Agregar otro correo electronico',
        send: 'Enviar',
        close: 'Cerrar',
        alertEmailSend: 'Email enviado',
        alertEmailSendTo: 'Enviado con éxito a',
        session: 'Sesión',
        foodServices: 'Productos/Servicios',
        ordersTickets: 'Órdenes/Tickets',
        reservationsDeliveries: 'Reservaciones/Entregas',
        restaurantAdministration: 'Administración',
        report: 'Reportes',
        sales: 'Ventas',
        staffOccupationsCustomersDrivers: 'Staff/Ocupaciones/Clientes/Choferes',
        marketing: 'Marketing',
        settings: 'Configuraciones',
        merchantSettings: 'Configuración del comerciante'
      },
      controlPanel: {
        subscription:
          'Ver y Crear Suscripciones y Facturación (Terminal Virtual)',
        staff:
          'Creación de cuentas de usuario de los trabajadores del negocio.',
        occupations:
          'Gestión de las ocupaciones del negocio. Las ocupaciones están relacionadas con los miembros del staff. Cada miembro tiene una ocupación específica.',
        roles:
          'Gestión de roles. Los roles establecen los permisos sobre las diferentes áreas de la aplicación y están relacionados con los miembros del staff. Un miembro del staff puede tener varios roles.',
        workSheet:
          'Gestión de hojas de trabajo. Las hojas de trabajo permiten llevar un control sobre el horario de los trabajadores.',
        customers: 'Muestra un listado de los clientes del negocio.',
        categories:
          'Gestiona las distintas categorías de los menús. Las categorías están relacionadas con los elementos del menú. Un elemento de menú pertenece a una categoría.',
        menuItems:
          'Gestión de los elementos del menú. Un menú está compuesto por categorías, y a su vez, los elementos del menú se relacionan con el menú a través de las categoría a la que pertenece.',
        services:
          'Gestión de los servicios. Los servicios se usan en los menús de catering.',
        menus:
          'Gestión de los menús. Permite la gestión de menús. Un menú está compuesto por diversas categorías.',
        orders: 'Ver y crear órdenes.',
        invoices: 'Ver y Crear Facturas.',
        quotes: 'Ver y Crear Propuestas.',
        cateringOrders: 'Permite la visualización de órdenes de catering.',
        orderForDelivery:
          'Muestra un listado de las órdenes que están listas para ser entregadas al chofer que le ha sido asignado la orden.',
        tickets:
          'Permite la creación y el seguimiento de tickets. Los tickets son órdenes que se mantienen abiertas mientra el cliente siga consumiendo.',
        otherCharges:
          'Permite la gestion de cargos adiciones por cantidad de productos.',
        taxes: 'Gestión de impuestos.',
        deliveries: 'Gestión de los precios de entrega.',
        reservations: 'Gestionar y crear reservas y listas de espera',
        tables: 'Gestión de las capacidades del negocio.',
        payments: 'Muestra el listado de las transacciones de pago realizadas.',
        aboutSettings:
          'Permite la gestión del slider que aparece en la página de inicio de cada negocio.',
        paymentSettings:
          'Permite la configuración de la pasarela de pago que se utilizará para procesar las transacciones.',
        basicSettings:
          'Permite la configuración de los datos principales del negocio: Nombre, Dirección, Teléfono. Esta información aparece en la página principal de cada negocio.',
        funtionalitiesSettings:
          'Permite la configuración del funcionamiento general del negocio: Horarios, Zip codes, propinas...',
        colorSettings:
          'Permite la configuración de los colores de la aplicación.',
        systemSettings:
          'Permite la configuración de la integración de iMenuApps® con aplicaciones de terceros.',
        driver: 'Administrar choferes externos.',
        credit: 'Permite la administración de créditos.'
      },
      options: {
        billingAddress: 'Direccion de la facturación',
        shippingAddress: 'Direccion de envío',
        placeholderNotes: 'Escribe tus notas aquí',
        dataRequired: 'Dato Requerido',
        customerArea: 'Area del cliente',
        customerName: 'Nombre del cliente',
        customerEmail: 'Email del cliente',
        customerPhone: 'Teléfono del cliente',
        customerAddress: 'Dirección del cliente',
        terms: 'Términos',
        trackingN: 'Número de rastreo',
        shippingDate: 'Fecha de envío',
        shippingTo: 'Enviar hacia',
        ivoiceDate: 'Fecha de factura',
        quoteDate: 'Fecha de cotización',
        subscriptionBilling: 'Facturación de suscripción',
        driverDeliveries: 'Entregas',
        activeDriverOrder: 'Órdenes activas',
        completedDriverOrder: 'Pedidos completados',
        useDirection: 'Click si es la misma direccion del cliente',
        menuTitle: 'ADMINISTRACIÓN',
        login: 'Iniciar Sesión',
        logout: 'Cerrar Sesión',
        controlPanel: 'Administración',
        supportPanel: 'Soporte',
        manageMenus: 'Menús',
        managePyments: 'Pagos',
        manageHouseAccount: 'Cuenta Casa',
        viewOrders: 'Ver/Crear Órdenes',
        viewQuotes: 'Propuestas',
        viewInvoice: 'Facturas',
        manageCategories: 'Categorías',
        manageProducts: 'Item',
        manageServices: 'Servicios',
        manageCustomers: 'Clientes',
        manageCredits: 'Créditos',
        manageTables: 'Mesas',
        manageTaxes: 'Impuestos',
        manageShippings: 'Envíos',
        manageOtherCharges: 'Otros Recargos',
        manageUsers: 'Equipo de trabajo',
        manageDriver: 'Choferes',
        manageRoles: 'Roles/Permisos',
        manageWorkSheet: 'Hoja de Trabajo',
        manageBasicSettings: 'Negocio',
        manageRestaurantSettings: 'Configuración de Negocio',
        manageAboutSettings: 'Acerca De Slides',
        manageFunSettings: 'Funcionamiento',
        manageColourSettings: 'Colores',
        manageDesign: 'Diseño',
        manageMore: 'Más',
        managePaymentSettings: 'Pagos',
        manageOccupation: 'Puestos',
        manageKeySettings: 'Sistemas Externos',
        supportSettings: 'Configuraciones de Soporte',
        changeServerId: 'Cambiar Employee ID',
        serverId: 'Clave de empleado',
        manageVariantGroup: 'Opciones',
        manageReservation: 'Reservaciones',
        manageWaitList: 'Lista de Espera',
        viewCateringOrders: 'Órdenes de Catering',
        forgotPassword: 'Olvidó su contraseña?',
        sendNewPassword: 'Enviar un nuevo password',
        recoveryPassword: 'Recuperar contraseña',
        refund: 'Refund',
        void: 'Void'
      },
      titles: {
        bankAccountName: 'Nombre de la cuenta de banco',
        routeNumber: 'Número de ruta',
        accountNumber: 'Número de cuenta',
        bankAccountAddress: 'Dirección de la cuenta de banco',
        nameAuthorizedPerson: 'Nombre de la persona autorizada',
        paymentAmount: 'Monto del pago',
        warning: 'Advertencia',
        messageChangeQuote:
          'Si cambia este prefijo nuevamente, la numeración de secuencia de sus Cotizaciones cambiará',
        messageChangeIvoice:
          'Si cambia este prefijo nuevamente, la numeración de secuencia de sus Facturas cambiará',
        messageStartingInvoiceNumber:
          'Si cambia este número inicial, la numeración de sus facturas se restablecerá para comenzar desde este número.',
        prefixQuotes: 'Prefijo para Cotizaciones (por ejemplo, QTE)',
        prefixInvoices: 'Prefijo para facturas (por ejemplo, INV)',
        invoiceStartingNumber: 'Número inicial de factura',
        moneyNotEnought: 'Cantidad debe ser mayor al total para cambio',
        mustBe: 'Debe tener 9 dígitos',
        controlPanel: 'Administración del Sistema',
        supportPanel: 'Soporte',
        menus: 'Menús',
        orders: 'Órdenes',
        quotes: 'Propuestas',
        invoices: 'Facturas',
        allOrders: 'Todas las Órdenes',
        customerCredit: 'Créditos de Clientes',
        workSheet: 'Hoja de Trabajo',
        cateringOrders: 'Órdenes de Catering',
        ordersForDelivery: 'Órdenes Para Entregar',
        suscriptors: 'Suscriptores',
        tickets: 'Órdenes de Tickets',
        categories: 'Categorías',
        products: 'Elementos de menú',
        services: 'Servicios',
        customers: 'Clientes',
        tables: 'Mesas',
        taxes: 'Impuestos',
        shippings: 'Precios de Envío',
        otherCharges: 'Otros Cargos',
        newsletter: 'Boletín',
        users: 'Equipo de Trabajo',
        suscriptor: 'Suscriptores',
        occupation: 'Puestos de Trabajo',
        roles: 'Roles',
        about: 'Acerca de',
        digitalScreens: 'Pantallas Digitales',
        editAboutForm: 'Editar Información de Acerca de',
        newAboutForm: 'Crear Información de Acerca de',
        editDisplayForm: 'Editar diapositiva de señalización digital',
        newDisplayForm: 'Crear diapositiva de señalización digital',
        basicConfig: 'Conf. Básica',
        functions: 'Funcionamiento',
        colors: 'Colores',
        variantGroup: 'Opciones',
        loading: 'Por favor, un momento...',
        editColorSetting: 'Editar  Colores',
        createColorSetting: 'Crear  Colores',
        orderCateringForm: 'Editar Orden de Catering',
        deliveryLocation: 'Ubicación de la entrega',
        cateringMenus: 'Menus de catering',
        payments: 'Pagos',
        ticketDetails: 'Detalles del Ticket',
        emptyResult: 'Sin resultados',
        cancellationType: 'Tipo de cancelación',
        changePassword: 'Cambiar contraseña',
        restaurantName: 'Nombre del negocio',
        clockinClockout: 'Hora de entrada - Hora de salida',
        clockin: 'Hora de entrada',
        clockout: 'Hora de salida',
        timecard: 'Asistencia',
        timesheet: 'Asistencia del día',
        staff: 'Personal'
      },
      orderState: {
        started: 'iniciada',
        inKitchen: 'en cocina',
        cooked: 'cocinada',
        pending: 'pendiente de entrega',
        delivery: 'entregada'
      },
      list: {
        buttons: {
          goToControlPanel: 'Volver a la administración'
        },
        actions: {
          addANew: 'Añadir',
          edit: 'Editar',
          delete: 'Eliminar',
          view: 'Ver'
        },
        entitiesName: {
          menu: 'menú',
          category: 'categoría',
          categoryPlaceholder: 'Seleccione categoría',
          product: 'elemento de menú',
          customer: 'cliente',
          table: 'mesa',
          tax: 'impuesto',
          taxPlaceholder: 'Seleccione impuesto',
          shipping: 'envío',
          otherCharge: 'otros cargos',
          user: 'usuario',
          order: 'órdenes',
          role: 'rol',
          about: 'acerca de'
        },
        messages: {
          confirmDelete: 'Confirmar eliminación',
          confirmAccept: 'Confirmar item',
          confirmProduct: 'Confirma la inclusión de este item?',
          deleteCategory: 'Quiere eliminar esta categoría?',
          deleteRestaurant: 'Quiere eliminar este negocio?',
          deleteOrder: 'Quiere eliminar esta order?',
          deleteCredit: 'Quiere eliminar este crédito?',
          deleteWorkSheet: 'Quiere eliminar esta hoja de trabajo?',
          deleteCustomer: 'Quiere eliminar este cliente?',
          deleteMenu: 'Quiere eliminar este menú?',
          deleteOtherCharge: 'Quiere eliminar este cargo?',
          deleteNewsletter: 'Quiere eliminar este boletín?',
          deleteProduct: 'Quiere eliminar este elemento de menú?',
          deleteShipping: 'Quiere eliminar este envío?',
          deleteTable: 'Quiere eliminar esta mesa?',
          deleteTax: 'Quiere eliminar este impuesto?',
          deleteUser: 'Quiere eliminar este usuario?',
          deleteSuscriptor: 'Quiere eliminar este suscriptor?',
          deleteRole: 'Quiere eliminar este rol?',
          deleteAbout: 'Quiere eliminar esta información?',
          deleteDisplay: '¿Quiere eliminar este anuncio?',
          deleteOccupation: 'Quiere eliminar este puesto?',
          deleteVariantGroup:
            'Quiere eliminar esta opción de elemento de menú?',
          infoDeleteSuccess: 'Información',
          messageDeleteSuccessCategory:
            'La categoría fue eliminada satisfactoriamente',
          messageDeleteSuccessRestaurant:
            'El negocio fue eliminado satisfactoriamente',
          messageDeleteSuccessOrder:
            'La orden fue eliminada satisfactoriamente',
          messageDeleteSuccessCredit:
            'El crédito fue eliminado satisfactoriamente',
          messageDeleteSuccessWorkSheet:
            'La hoja de trabajo fue eliminada satisfactoriamente',
          messageDeleteSuccessCustomer:
            'El cliente fue eliminado satisfactoriamente',
          messageDeleteSuccessMenu: 'El menú fue eliminado satisfactoriamente',
          messageDeleteSuccessOtherCharges:
            'El cargo fue eliminado satisfactoriamente',
          messageDeleteSuccessNewsletter:
            'El boletín fue eliminado satisfactoriamente',
          messageDeleteSuccessProduct:
            'El elemento de menú fue eliminado satisfactoriamente',
          messageDeleteSuccessShipping:
            'El envío fue eliminado satisfactoriamente',
          messageDeleteSuccessTable: 'La mesa fue eliminada satisfactoriamente',
          messageDeleteSuccessTax:
            'El impuesto fue eliminado satisfactoriamente',
          messageDeleteSuccessUser:
            'El usuario fue eliminado satisfactoriamente',
          messageDeleteSuccessSuscriptor:
            'El suscriptor fue eliminado satisfactoriamente',
          messageActivareSuccessSuscriptor:
            'El suscriptor fue activado satisfactoriamente',
          messageDesactivareSuccessSuscriptor:
            'El suscriptor fue desactivado satisfactoriamente',
          messageDeleteSuccessRole: 'El rol fue eliminado satisfactoriamente',
          messageDeleteSuccessAbout:
            'La información fue eliminada satisfactoriamente',
          messageDeleteSuccessOccupation:
            'El puesto fue eliminado satisfactoriamente',
          messageDeleteSuccessVariantGroup:
            'La opción fue eliminada satisfactoriamente',
          messageCateringApproved:
            'La orden de catering ha sido aprovada satisfactoriamente',
          messageCateringCancelled:
            'La orden de catering fue cancelada satisfactoriamente',
          titleEditCategory: 'Editar categoría',
          titleEditCustomer: 'Editar cliente',
          titleEditMenu: 'Editar menú',
          titleEditOtherCharges: 'Editar cargo',
          titleEditProduct: 'Editar elemento de menú',
          titleEditShipping: 'Editar envío',
          titleEditTable: 'Editar mesa',
          titleEditTax: 'Editar impuesto',
          titleEditUser: 'Editar usuario',
          titleEditRole: 'Editar rol',
          titleEditSetting: 'Editar configuración',
          titleEditOccupation: 'Editar puesto',
          titleCreateCategory: 'Crear categoría',
          titleCreateCustomer: 'Crear cliente',
          titleCreateMenu: 'Crear menú',
          titleCreateOtherCharges: 'Crear cargo',
          titleCreateProduct: 'Crear elemento ',
          titleCreateShipping: 'Crear envío',
          titleCreateTable: 'Crear mesa',
          titleCreateTax: 'Crear impuesto',
          titleCreateUser: 'Crear usuario',
          titleCreateRole: 'Crear rol',
          titleCreateSetting: 'Crear configuración',
          titleCreateOccupation: 'Crear puesto',
          titleEditVariantGroup: 'Editar opción',
          titleCreateVariantGroup: 'Crear opción',
          messageEditSuccessCategory:
            'La categoría fue editada satisfactoriamente',
          messageEditSuccessCredit: 'El crédito fue editado satisfactoriamente',
          messageEditSuccessCustomer:
            'El cliente fue editado satisfactoriamente',
          messageEditSuccessMenu: 'El menú fue editado satisfactoriamente',
          messageEditSuccessWorksheet:
            'La hoja de trabajo fue editada satisfactoriamente',
          messageEditSuccessOtherCharges:
            'El cargo fue editado satisfactoriamente',
          messageEditSuccessProduct:
            'El elemento de menú fue editado satisfactoriamente',
          messageEditSuccessShipping: 'El envío fue editado satisfactoriamente',
          messageEditSuccessTable: 'La mesa fue editada satisfactoriamente',
          messageEditSuccessTax: 'El impuesto fue editado satisfactoriamente',
          messageEditSuccessUser: 'El usuario fue editado satisfactoriamente',

          messageEditSuccessSuscriptor:
            'El suscriptor fue editado satisfactoriamente',
          messageEditSuccesRole: 'El rol fue editado satisfactoriamente',
          messageEditSuccessSetting:
            'Las configuraciones fueron editadas satisfactoriamente',
          messageEditSuccessOccupation:
            'El puesto fue editado satisfactoriamente',
          messageEditSuccessVariantGroup:
            'La opción fue editada satisfactoriamente',
          messageDeleteSuccessVariant:
            'La opción fue eliminada satisfactoriamente',
          messageEditSuccessVariant: 'La opción fue editada satisfactoriamente',
          messageCreateSuccessVariant:
            'La opción fue creada satisfactoriamente',
          messageCreateSuccessCategory:
            'La categoría fue creada satisfactoriamente',
          messageCreateSuccessCredit:
            'El crédito fue creado satisfactoriamente',
          messageCreateSuccessCustomer:
            'El cliente fue creado satisfactoriamente',
          messageCreateSuccessMenu: 'El menú fue creado satisfactoriamente',
          messageCreateSuccessWorksheet:
            'La hoja de trabajo fue creada satisfactoriamente',
          messageCreateSuccessOtherCharges:
            'El cargo fue creado satisfactoriamente',
          messageCreateSuccessProduct:
            'El elemento de menú fue creado satisfactoriamente',
          messageCreateSuccessShipping:
            'El envío fue creado satisfactoriamente',
          messageCreateSuccessTable: 'La mesa fue creada satisfactoriamente',
          messageCreateSuccessTax: 'El impuesto fue creado satisfactoriamente',
          messageCreateSuccessUser: 'El usuario fue creado satisfactoriamente',

          messageCreateSuccessSuscriptor:
            'El suscriptor fue creado satisfactoriamente',
          messageCreateSuccessRole: 'El rol fue creado satisfactoriamente',
          messageCreateSuccessSetting:
            'La configuración fue creada satisfactoriamente',
          messageCreateSuccessOccupation:
            'El puesto fue creado satisfactoriamente',
          messageCreateSuccessVariantGroup:
            'La opción fue creada satisfactoriamente',
          menuChangeState: 'El estado del menú se cambió satisfactoriamente',
          activeMenu: 'Activar menú',
          otherChargeChangeState:
            'El estado del cargo se cambió satisfactoriamente',
          activeOtherCharge: 'Activar cargo',
          taxChangeState: 'El estado del impuesto se cambió satisfactoriamente',
          activeTax: 'Activar impuesto',
          shippingChangeState:
            'El estado del envío se cambió satisfactoriamente',
          activeShipping: 'Activar envío',
          deleteSubtitleCategory: 'Eliminar categoría',
          deleteSubtitleCustomer: 'Eliminar cliente',
          deleteSubtitleMenu: 'Eliminar menú',
          deleteSubtitleOtherCharge: 'Eliminar cargo',
          deleteSubtitleProduct: 'Eliminar elemento de menú',
          deleteSubtitleShipping: 'Eliminar envío',
          deleteSubtitleTable: 'Eliminar mesa',
          deleteSubtitleTax: 'Eliminar impuesto',
          deleteSubtitleUser: 'Eliminar usuario',
          deleteSubtitleRole: 'Eliminar rol',
          deleteSubtitleAbout: 'Eliminar información',
          deleteSubtitleOccupation: 'Eliminar puesto',
          deleteSubtitleVariantGroup: 'Eliminar opción',
          errorTitle: 'Error',
          errorMessage: 'Lo sentimos, ocurrió un error de conexión.',
          changeOrderStateMessage: 'Cambiar estado de la orden',
          changeOrderStateSuccess:
            'El estado de la orden fue cambiado satisfactoriamente',
          changeOrderStateCanceled: 'La orden fue cancelada',
          connectionError: 'Error de conexión',
          sharedWorksheet: 'Hoja de trabajo compartida con el Staff',
          connectionErrorMessage:
            'Hubo un error de conexión. Por favor, verifique su conexión a internet y vuelva a intentarlo. Si el problema persiste, contacte a su proveedor de servicios.',
          newPasswordSent: 'La contraseña nueva fue enviada a su email.',
          emailOrPasswordIncorrect: 'El email o la contraseña es incorrecta',
          buttons: {
            cancel: 'Cancelar',
            delete: 'Eliminar',
            close: 'Cerrar',
            goToAdministration: 'Administración',
            goToList: 'Ir al listado',
            retry: 'Reintentar'
          }
        }
      },
      form: {
        login: 'Iniciar sesión',
        colour: {
          primaryColor: 'Color primario',
          primary: 'Primario',
          primaryText: 'Texto primario',
          secondaryColor: 'Color secundario',
          secondary: 'Secundario',
          secondaryText: 'Texto secundario',
          tertiaryColor: 'Color terciario',
          tertiary: 'Terciario',
          tertiaryText: 'Texto terciario',
          successColor: 'Color success',
          success: 'Success',
          successText: 'Texto success',
          dangerColor: 'Color danger',
          danger: 'Danger',
          dangerText: 'Texto danger',
          backgroundColor: 'Color de fondo',
          background: 'Fondo'
        },
        tableShape: {
          square: 'Cuadrada',
          rectangular: 'Rectangular',
          circle: 'Circular',
          oval: 'Ovalada'
        },
        buttons: {
          backToCategoriesList: 'Regresar al listado de categorías',
          backToCustomersList: 'Regresar al listado de clientes',
          backToMenusList: 'Regresar al listado de menús',
          backToOrdersList: 'Regresar al listado de órdenes',
          backToOtherChargesList: 'Regresar al listado de otros cargos',
          backToProductsList: 'Regresar al listado de elementos de menú',
          backToShippingsList: 'Regresar al listado de envíos',
          backToTablesList: 'Regresar al listado de mesas',
          backToTaxesList: 'Regresar al listado de impuestos',
          backToUsersList: 'Regresar al listado de usuarios',
          backToRolesList: 'Regresar al listado de roles',
          backToAboutList: 'Regresar al listado de acerca de',
          backToMenu: 'Regresar al menú',
          cancelOrder: 'Cancelar orden',
          changeState: 'Cambiar estado a',
          defaultColor: 'Restablecer',
          addChoice: 'Añadir Variante',
          back: 'Atrás',
          save: 'Guardar',
          submit: 'Entregar',
          change: 'Cambiar',
          add: 'Añadir'
        },
        titles: {
          invoiceReminderLabel:
            'Ingrese cada cuantos días se enviara recordatorio',
          invoiceReminder: 'Recordatorios de facturas de activación',
          assetPickupRequest: 'Solicitud de recogida de activos',
          pickupAddress: 'Dirección de entrega',
          requireDeposit: 'Requerir depósito',
          lateFees: 'Factura de cargos por pago atrasado',
          lateFeesLabel: 'Ingrese el porcentaje de mora a cobrar',
          thisInvoice: 'Esta factura ha sido pagada',
          customerNewTitle: 'Crear un nuevo cliente',
          customerEditTitle: 'Editar cliente',
          categoryNewTitle: 'Crear una nueva categoría',
          creditNewTitle: 'Crear un nuevo crédito',
          categoryEditTitle: 'Editar categoría',
          creditEditTitle: 'Editar crédito',
          menuNewTitle: 'Crear un nuevo menú',
          workSheetNewTitle: 'Crear Hoja de Trabajo',
          menuEditTitle: 'Editar menú',
          workSheetEditTitle: 'Editar Hoja de Trabajo',
          productNewTitle: 'Crear elemento de menú',
          serviceNewTitle: 'Crear un servicio',
          productEditTitle: 'Editar elemento de menú',
          serviceEditTitle: 'Editar servicio',
          changePaymentSetting: 'Cambiar configuración del pago',
          shippingNewTitle: 'Crear un nuevo envío',
          shippingEditTitle: 'Editar envío',
          taxNewTitle: 'Crear un nuevo impuesto',
          taxEditTitle: 'Editar impuesto',
          tableNewTitle: 'Crear una nueva mesa',
          tableEditTitle: 'Editar mesa',
          packageNewTitle: 'Create a new package',
          packageEditTitleditTitle: 'Edit package',
          variantGroupEditTitle: 'Editar opción',
          variantGroupNewTitle: 'Crear opción',
          otherChargeNewTitle: 'Crear otro cargo',
          otherChargeEditTitle: 'Editar otro cargo',
          userNewTitle: 'Crear un nuevo miembro del staff',

          suscriptionNewTitle: 'Crear una nueva suscripción',

          driversNewTitle: 'Crear un nuevo chofer',
          userEditTitle: 'Editar miembro del staff',

          suscriptionEditTitle: 'Editar suscripción',

          driversEditTitle: 'Editar choferes',
          supportUsers: 'Usuarios de soporte',
          occupationNewTitle: 'Crear Nueva Ocupación/Trabajo',
          occupationEditTitle: 'Editar Ocupación/Trabajo',
          roleNewTitle: 'Agregar permisos',
          roleEditTitle: 'Editar rol',
          selectAProduct: 'Seleccione un elemento de menú',
          selectACategory: 'Seleccione una categoría',
          selectACustomer: 'Seleccione un cliente',
          selectATax: 'Seleccione un impuesto',
          selectARestaurant: 'Seleccione un negocio',
          selectAState: 'Seleccione un estado',
          filterCategory: 'Filtre...',
          selectALanguage: 'Idioma',
          selectAnOccupation: 'Seleccione un puesto',
          selectACooker: 'Seleccione un cocinero',
          selectADriver: 'Seleccione un chofer',
          allProducts: 'Todos los elementos de menú:',
          settingEditTitle: 'Editar configuración',
          generalSettingEditTitle: 'Editar configuraciones básicas',
          generalSettingNewTitle: 'Crear configuraciones básicas',
          keySettingEditTitle: 'Editar configuraciones del sistema',
          keySettingNewTitle: 'Crear configuraciones del sistema',
          funSettingEditTitle: 'Configuraciones de Funcionamiento',
          funSettingNewTitle: 'Crear configuraciones de funcionamiento',
          settingNewTitle: 'Añadir configuración',
          pickFromSelect: 'Seleccionar inicio de recogida',
          pickToSelect: 'Seleccionar fin de recogida',
          dateFromSelect: 'Seleccionar fecha de inicio',
          dateToSelect: 'Seleccionar fecha de fin',
          cancelOrder: 'Cancelar orden',
          drivers: 'Choferes',
          customer: 'Cliente',
          date: 'Fecha',
          startDate: 'Fecha de Inicio',
          endDate: 'Fecha de Fin',
          orderType: 'Tipo de orden',
          addressToDelivery: 'Dirección de entrega',
          hourToPick: 'Hora de recogida',
          tableService: 'Mesa de servicio',
          products: 'Elementos de menú',
          services: 'Servicios',
          otherCharges: 'Otros cargos',
          shippingPrice: 'Precio de entrega',
          subtotal: 'Subtotal',
          tax: 'Total de impuestos',
          tip: 'Propina',
          total: 'Total',
          pendingPay: 'Pendiente de pago',
          payed: 'Pagado',
          notes: 'Notas',
          cancelNotes: 'Notas de cancelación',
          pickupNotes: 'Notas de recogida',
          cooker: 'Cocinero',
          state: 'Estado',
          generalConfiguration: 'CONFIGURACIONES GENERALES',
          paymentConfiguration: 'CONFIGURACIONES DEL PAGO',
          cashDiscount: 'DESCUENTO DE EFECTIVO',
          eposConfiguration: 'CONFIGURACIONES EPOS',
          emailConfiguration: 'CONFIGURACIONES DE EMAIL',
          twilioSMS: 'MENSAJERÍA CON TWILIO',
          sinchSMS: 'MENSAJERÍA CON SINCH',
          selectAVariantGroup: 'Selecciona una opción',
          imageText:
            'Por favor suba imágenes de 200x200 para obtener mejores resultados. Asegúrese de que la imágen no esté bajo derechos de autor.',
          orderCateringForm: 'Aprobación de la orden de catering',
          restaurantHours: 'Horario del Negocio',
          deadlinesNotes:
            'Por favor, asegúrese que la sumatoria de los plazos es 100.',
          selectDate: 'Seleccione fecha',
          toPay: 'A pagar',
          pending: 'Pendiente',
          genaral: 'General',
          others: 'Otros',
          variants: 'Variantes',
          sides: 'Acompañantes',
          ingredients: 'Ingredientes',
          shift4: 'Shift4',
          auth: 'Authorize.Net',
          capcha: 'Capcha',
          zipCodes: 'Códigos postales',
          backups: 'Backups',
          devices: 'Dispositivos',
          creditSetting: 'Configuración de Crédito'
        },
        fields: {
          changeImage: 'Cambiar Imagen',
          addImage: 'Añadir Imagen',
          customerHours: 'Personalizar Horas',
          name: 'Nombre',
          menuName: 'Nombre del menú',
          description: 'Descripción',
          email: 'Email',
          password: 'Contraseña',
          date: 'Fecha',
          image: 'Imagen',
          phone: 'Teléfono',
          productMin: 'Mínimo de elementos de menú',
          productMax: 'Máximo de elementos de menú',
          price: 'Precio',
          extraCharge: 'Cargo Extra',
          costPrice: 'Costo',
          retailPrice: 'Venta',
          stimateTime: 'Stimate Time (minutes)',
          stockQuantity: 'Cantidad en Stock',
          minReorderPoint: 'Cantidad Mínima en Stock',
          maxReorderPoint: 'Cantidad Máxima en Stock',
          CreditAmount: 'Total del Crédito',
          vendorName: 'Nombre del Distribuidor',
          vendorCode: 'Código del Distribuidor',
          shortDescription: 'Descripción Corta',
          keywords: 'Palabras Claves',
          tags: 'Etiquetas',
          binNumber: 'Número Bin',
          addSerialNumber: 'Número Serial Add',
          active: 'Activar',
          save: 'Ahorrar',
          submitToken: 'Enviar Token',
          available: 'Disponible',
          availableCategories: 'CATEGORÍAS DISPONIBLES',
          availableProducts: 'ELEMENTOS DE MENU DISPONIBLES',
          availableStaff: 'TRABAJADORES DISPONIBLES',
          selectedProducts: 'ELEMENTOS DE MENU SELECCIONADOS',
          selectedCategories: 'CATEGORÍAS SELECCIONADAS',
          selectedStaff: 'TRABAJADORES SELECCIONADOS',
          workedHours: 'HORAS TRABAJADAS',
          scheduledDates: 'Fechas programadas',
          selectedAggregates: 'AGREGADOS SELECCIONADOS',
          marketingConsent: 'LABOR DE MARKETING',
          product: 'Elemento de menú',
          category: 'Categoría',
          tax: 'Impuesto',
          parentCategory: 'Categoría padre',
          qrCode: 'Código QR',
          capacity: 'Capacidad',
          percent: 'Porcentaje',
          priority: 'Prioridad (0 - 1)',
          permissions: 'Permisos',
          permissionsRole: 'Roles de Permiso',
          selectCashDiscount: 'Seleccione Descuento por pronto pago',
          roles: 'Puesto/Trabajo',
          address: 'Dirección',
          title: 'Título',
          subtitle: 'Subtítulo',
          online: 'En Línea',
          showOtherRestaurant: 'Mostrar otros negocios',
          reservationByTable: 'Reservar mesas',
          reservationByStaff: 'Reservar trabajador',
          tablesChoose: 'Los clientes pueden escoger ubicación',
          onlineMss: 'Negocio no está visible para el público ',
          onlineMss1: 'Negocio visible para el público ',
          mustClockinMss: 'Usted debe marcar la entrada primero. Por favor, ',
          mustClockin2Mss: 'marque la entrada.',
          SelectPickHour: 'Seleccionar hora de recogida',
          SelectPickHour2: 'Activar hora de recogida',
          TipRequire: 'Propina obligatoria',
          tips: 'Propinas',
          assetPickup: 'Recogida de activos',
          depositAmount: 'Cantidad del depósito',
          typeTip: 'Escriba un porciento de propina',
          MinTimeToCook: 'Tiempo mínimo de cocción',
          PickFrom: 'Recoger a partir de',
          PickToToolTip: 'Agrega el final de tu tiempo de entrega',
          PickFromToolTip: 'Agrega el inicio de tu tiempo de entrega',
          PickTo: 'Recoger hasta',
          DateFrom: 'Fecha de Inicio',
          DateTo: 'Fecha de Fin',
          DateLimit: 'Fecha límite de pago',
          ShowCooker: 'Mostrar cocinero',
          zipCodes: 'Código zip',
          deliveryZone: 'Zonas de entrega',
          cooker: 'Cocinero',
          driver: 'Chofer',
          rejectedOrder: 'Orden rechazada por la conductora',
          cancelationNote: 'Nota de cancelación',
          tableNumber: 'Número de mesa',
          firstName: 'Nombre(s)',
          lastName: 'Apellidos',
          occupation: 'Puesto',
          confirmPassword: 'Confirme contraseña',
          endPointUrl: 'Endpoint Url',
          capchaKey: 'Capcha key',
          clientGuid: 'Client GUID',
          interfazVersion: 'Versión de la interfaz',
          interfazName: 'Nombre de la interfaz',
          companyName: 'Nombre de la compañía',
          apiLoginId: 'API login Id',
          epos: 'Integración con ePos',
          eposToken: 'ePos token',
          transactionKey: 'Transaction Key',
          smtpHost: 'SMTP Host',
          port: 'Puerto',
          secure: 'Seguro',
          twFromNumber: 'Número de origen',
          twAccountSID: 'SID de la cuenta',
          twToken: 'Token',
          sinchFromNumber: 'Número de origen',
          sinchAccountSID: 'Id del plan de servicio',
          sinchToken: 'Token',
          web: 'Sitio web',
          variantGroup: 'Opciones',
          haveSides: 'Agregados',
          cantOfSides: 'Cantidad de agregos incluidos',
          categoryOfSides: 'Filtre los agregos por categoría',
          addIngredients: 'Especificar ingredientes',
          typeAnIngredient: 'Entre un ingrediente',
          canViewGeoposition: 'Puede ver geolocalización',
          canViewRating: 'Puede ver el rating',
          viewDelivery: 'Puede crear órdenes de entrega',
          viewOnTable: 'Puede crear órdenes en la mesa',
          viewCurbside: 'Puede crear órdenes para recoger en auto',
          tablePrefix: 'Prefijo de mesa',
          barPrefix: 'Prefijo de barra',
          roomPrefix: 'Prefijo de habitación',
          typeZipCode: 'Escriba un zipcode',
          isCatering: 'Es de catering',
          isService: 'Menú de servicio',
          isReservationMenu: 'Menú de reservación',
          catering: 'Catering',
          minAmoutCatering: 'Monto mínimo para catering',
          minAmountCateringDelivery: 'Monto mínimo para envío',
          loyaltyMinPointToBuy: 'Mínimo de puntos para comprar',
          loyaltyExpirationMonths:
            'Cantidad de meses para expiración del loyalty program',
          loyaltyPointMoney: 'Cantidad de puntos por $1',
          partialPay: 'Pagos parciales',
          payForQuotation: 'Pre pago por cotización',
          percentPayForQuotation: 'Monto de pre pago por cotización',
          service: 'Servicio',
          showServicePrice: 'Mostrar precio del servicio',
          viewCatering: 'Ver catering',
          viewLoyalty: 'Ver loyalty program',
          viewReservation: 'Ver reservación',
          reservation: 'Reservación',
          loyalty: 'Programa de Loyalty',
          minDayToReservation: 'Margen de reserva',
          viewCustomerReservation: 'Ver reservaciones de clientes',
          sameHourForAllDays: 'El mismo horario para todos los días',
          followingDays: 'Mismo horario para los siguientes días.',
          timezone: 'Timezone',
          monday: 'Lunes',
          tuesday: 'Martes',
          wednesday: 'Miércoles',
          thursday: 'Jueves',
          friday: 'Viernes',
          saturday: 'Sábado',
          sunday: 'Domingo',
          allDays: 'Todos los días',
          marginDays: 'Minima cantidad de días para orden',
          cateringStates: 'Estados para catering',
          typeState: 'Escriba el código del estado',
          zipCodesExclude: 'Zipcodes a excluir',
          cateringEvents: 'Eventos de catering',
          typeEventName: 'Escribe un nombre de evento',
          proposeNewDataTime: 'Proponer nueva fecha/hora',
          fixed: 'Monto fijo',
          amount: 'Monto',
          discount: 'Descuento',
          deadlines: 'Plazos',
          pendingPay: 'Pendiente de pago',
          payed: 'Pagado',
          states: 'Estados',
          paymentMethod: 'Método de pago',
          cash: 'Efectivo',
          card: 'Tarjeta',
          check: 'Cheque',
          free: 'Libre',
          busy: 'Ocupado',
          dirty: 'Sucio',
          authenticationToken: 'Token de autorización',
          authenticationTokenMoto: 'Token de autorización para MOTO',
          authenticationTokenFB: 'Token de autorización para FB',
          accountType: 'Tipo de cuenta',
          method: 'Método',
          serverName: 'Nombre del dependiente',
          urlLocation: 'Url de la localización',
          fax: 'Fax',
          oldServerId: 'Antiguo Employee ID',
          newServerId: 'Nuevo Employee ID',
          acivateTwilio: 'Activar Twilio',
          acivateSinch: 'Activar Sinch',
          isDriver: 'Es un chofer',
          monthy: 'Mensual',
          anual: 'Anual',
          merchantOnboardingApplication:
            'Aplicación de incorporación de comerciantes',
          applicationName: 'Nombre del aplicación',
          agentId: 'ID del agente',
          planId: 'ID del plan',
          dateOfBirth: 'Fecha de nacimiento',
          equityOwnershipPercent: 'Porcentaje de propiedad accionaria',
          isPersonalGuarantor: '¿Es Garantía Personal?',
          showProgressBar: 'Mostrar barra de progreso a la conductora'
        },
        messages: {
          buttons: {
            ok: 'Ok',
            dismiss: 'Descartar'
          },
          activatePaymentMethod: 'ha sido activado.',
          incorrectToken: 'El token enviado no es correcto.',
          activationMethodError:
            'El método de activación ha devuelto un error.',
          unexpectedError: 'Ha ocurrido un error inesperado.',
          savePaymentMethodSuccess:
            'Se ha guardado la configuración del método de pago.',
          savePaymentMethodError:
            'Ha ocurrido un error al guardar la configuración del método de pago.'
        },
        validate: {
          validate: 'Validación',
          validateCategory: 'Validar categoría',
          validateCustomer: 'Validar cliente',
          validateMenu: 'Validar menú',
          validateOtherCharge: 'Validar otros cargos',
          validateProduct: 'Validar elemento de menú',
          validateShipping: 'Validar envío',
          validateTable: 'Validar mesa',
          validateTax: 'Validar impuesto',
          validateUser: 'Validar usuario',
          validateRole: 'Validar rol',
          validateSetting: 'Validar configuración',
          validateOccupation: 'Validar puesto',
          validateVariantGroup: 'Validar opción',
          name: 'El nombre es requerido',
          image: 'La imagen es requerida',
          email: 'El email es requerido',
          phone: 'El teléfono es requerido',
          emailIncorrect: 'El email es incorrecto',
          date: 'La fecha es requerida',
          selectedCategories: 'Debe seleccionar al menos una categoría',
          productMinNumber:
            'Los elementos de menú mínimos debe ser un valor numérico',
          productMaxNumber:
            'Los elementos de menú máximos debe ser un valor numérico',
          productMinGreater:
            'Los elementos de menú mínimos deben ser mayor que 0',
          productMaxGreater:
            'Los elementos de menú máximos deben ser mayor de 2',
          price: 'El precio debe ser numérico',
          priceGreater: 'El precio debe ser mayor que 0',
          product: 'Debe seleccionar un elemento de menú',
          costPriceNumber: 'El precio de costo debe ser un valor numérico',
          retailPriceNumber: 'El precio de venta debe ser un valor numérico',
          retailPriceShouldBeGreaterThanSalePrice:
            'El Precio de Venta al Público siempre debe ser mayor que el Precio de Oferta.',
          costPriceGreater: 'El precio de costo debe ser mayor que 0',
          retailPriceGreater: 'El precio de venta debe ser mayor que 0',
          category: 'Debe seleccionar una categoría',
          capacity: 'La capacidad debe ser numérica',
          capacityGreater: 'La capacidad debe ser mayor que 0',
          percent: 'El porciento es requerido',
          percentGreater: 'El porciento debe ser mayor que 0',
          password: 'El password es requerido',
          address: 'La dirección es requerida',
          title: 'El título es requerido',
          subtitle: 'El subtítulo es requerido',
          zipCode: 'El zip code no es un zip code válido',
          duplicateZipCode: 'El zip code ya se encuentra en la lista',
          cateringPriceZero: 'El precio del catering es cero',
          cooker: 'Debe llenar el campo cocinero',
          tableNumber: 'La mesa debe tener un número',
          tableNumberGreater: 'El número de la mesa debe ser mayor que 0',
          nameDuplicate:
            'Ya existe una mesa registrada con este nombre. Debe cambiar el número o el tipo.',
          firstName: 'Debe llenar el campo nombre(s)',
          lastName: 'Debe llenar el campo apellidos',
          confirmPassword:
            'La contraseña y la contraseña de confirmación deben ser iguales',
          occupation: 'Debe llenar el campo puesto',
          notes: 'Debe poner una nota de cancelación',
          tip: 'El impuesto no puede ser cero',
          tipRepeat: 'El impuesto existe. Por favor, escriba un nuevo número.',
          emailDontExist: 'El email no existe',
          ingredient: 'El ingrediente ya existe.'
        },
        permissionsGroup: {
          permissionMenu: 'PERMISOS  DE MENÚS',
          permissionCategory: 'PERMISO DE CATEGORÍAS',
          permissionProduct: 'PERMISO DE ELEMENTOS DE MENU',
          permissionSpecialPrice: 'PERMISO DE PRECIOS ESPECIALES',
          permissionCustomer: 'PERMISOS DE CLIENTES',
          permissionCredit: 'PERMISOS DE CRÉDITOS',
          permissionHouseAccount: 'PERMISOS DE CUENTAS DE LA CASA',
          permissionTable: 'PERMISOS DE MESAS',
          permissionTax: 'PERMISOS DE IMPUESTOS',
          permissionShipping: 'PERMISOS DE ENVÍOS',
          permissionOtherCharge: 'PERMISOS DE RECARGOS',
          permissionUser: 'PERMISOS DE STAFF',
          permissionDriver: 'PERMISO DE SUPERVICIÓN DEL CONDUCTOR',
          permissionDeliveryDriver: 'PERMISO DEL CONDUCTOR DE ENTREGA',
          permissionOrder: 'PERMISOS DE ÓRDENES',
          permissionRole: 'PERMISOS DE ROLES',
          permissionWorkSheet: 'PERMISOS DE HOJAS DE TRABAJO',
          permissionSetting: 'PERMISOS DE CONFIGURACIÓN',
          permissionPayment: 'PERMISOS DE PAGO',
          permissionReport: 'PERMISO DE INFORMES',
          permissionClockin: 'PERMISOS DE ENTRADA',
          permissionOccupation: 'PERMISOS DE PUESTOS',
          permissionVariantGroup: 'PERMISOS DE OPCIONES DE ELEMENTOS DE MENU',
          permissionReservation: 'PERMISOS DE RESERVACIÓN',
          permissionSuscriptors: 'PERMISOS DE SUSCRIPCIÓN',
          permissionSuscription: 'SUBSCRIPTION PERMISSION',
          canViewMenu: 'Puede ver menús',
          canCreateMenu: 'Puede crear menús',
          canEditMenu: 'Puede editar menús',
          canDeleteMenu: 'Puede eliminar menús',
          canViewCategory: 'Puede ver categorías',
          canCreateCategory: 'Puede crear categorías',
          canEditCategory: 'Puede editar categorías',
          canDeleteCategory: 'Puede eliminar caegorías',
          canViewProduct: 'Puede ver elementos de menú',
          canCreateProduct: 'Puede crear elementos de menú',
          canEditProduct: 'Puede editar elementos de menú',
          canDeleteProduct: 'Puede eliminar elementos de menú',
          canViewSpecialPrices: 'Puede ver precios especiales',
          canCreateSpecialPrices: 'Puede crear precios especiales',
          canEditSpecialPrices: 'Puede editar precios especiales',
          canDeleteSpecialPrices: 'Puede eliminar precios especiales',
          canViewCustomer: 'Puede ver clientes',
          canCreateCustomer: 'Puede crear clientes',
          canEditCustomer: 'Puede editar clientes',
          canDeleteCustomer: 'Puede eliminar clientes',
          canViewCredit: 'Puede ver créditos',
          canCreateCredit: 'Puede crear créditos',
          canEditCredit: 'Puede editar créditos',
          canDeleteCredit: 'Puede eliminar créditos',
          canViewHouseAccount: 'Puede ver cuentas de la casa',
          canCreateHouseAccount: 'Puede crear cuentas de la casa',
          canCreateSuscriptor: 'Puede crear suscriptores',
          canViewSuscriptor: 'Puede ver suscriptores',
          canEditSuscriptor: 'Puede editar suscriptores',
          canDesactivateSuscriptor: 'Puede desactivar suscriptores',
          canViewTable: 'Puede ver mesas',
          canCreateTable: 'Puede crear mesas',
          canEditTable: 'Puede editar mesas',
          canDeleteTable: 'Puede eliminar mesas',
          canViewTax: 'Puede ver impuestos',
          canCreateTax: 'Puede crear impuestos',
          canEditTax: 'Puede editar impuestos',
          canDeleteTax: 'Puede eliminar impuestos',
          canViewShipping: 'Puede ver envíos',
          canCreateShipping: 'Puede crear envíos',
          canEditShipping: 'Puede editar envíos',
          canDeleteShipping: 'Puede eliminar envíos',
          canViewOtherCharge: 'Puede ver otros cargos',
          canCreateOtherCharge: 'Puede crear otros cargos',
          canEditOtherCharge: 'Puede editar otros cargos',
          canDeleteOtherCharge: 'Puede eliminar otros cargos',
          canViewUser: 'Puede ver usuarios',
          canCreateUser: 'Puede crear usuarios',
          canEditUser: 'Puede editar usuarios',
          canDeleteUser: 'Puede eliminar usuarios',
          canViewDriver: 'Puede ver choferes externos',
          canCreateDriver: 'Puede crear choferes externos',
          canEditDriver: 'Puede editar choferes externos',
          canDeleteDriver: 'Puede eliminar choferes externos',
          canViewDeliveryDriver: 'Puede ver el conductor de entrega',
          canEditDeliveryStatus: 'Puede editar el estado de entrega',
          canCreateDeliveryDriver: 'Puede crear un conductor de entrega',
          canDeleteDeliveryDriver: 'Puede eliminar el conductor de entrega',
          canViewOccupation: 'Puede ver puestos',
          canCreateOccupation: 'Puede crear puestos',
          canEditOccupation: 'Puede editar puestos',
          canDeleteOccupation: 'Puede eliminar puestos',
          canViewRole: 'Puede ver roles',
          canCreateRole: 'Puede crear roles',
          canEditRole: 'Puede editar roles',
          canDeleteRole: 'Puede eliminar roles',
          canViewWorkSheet: 'Puede ver hojas de trabajo',
          canCreateWorkSheet: 'Puede crear hojas de trabajo',
          canEditWorkSheet: 'Puede editar hojas de trabajo',
          canDeleteWorkSheet: 'Puede eliminar hojas de trabajo',
          canViewOrder: 'Puede ver órdenes',
          canEditOrder: 'Puede editar órdenes',
          canCreateOrder: 'Puede crear órdenes',
          canViewOrderForDelivery: 'Puede ver órdenes para entregar',
          canChangeSetting: 'Puede cambiar las configuraciones',
          canViewVariantGroup: 'Puede ver opciones de elementos de menú',
          canCreateVariantGroup: 'Puede crear opciones de elementos de menú',
          canEditVariantGroup: 'Puede editar opciones de elementos de menú',
          canDeleteVariantGroup: 'Puede eliminar opciones de elementos de menú',
          canViewReservation: 'Puede ver reservaciones',
          canCreateReservation: 'Puede crear reservaciones',
          canEditReservation: 'Puede editar reservaciones',
          canDeleteReservation: 'Puede eliminar reservaciones',
          canViewPayment: 'Puede ver los pagos',
          canIgnoreClockin: 'Puede ignorar horario de entrada',
          canViewSalesByCustomer: 'Puede ver las ventas por cliente',
          canViewSalesByProduct: 'Puede ver las ventas por producto',
          canViewDynamicReports: 'Puede ver publicaciones dinámicas',
          canViewSubscription: 'Can view Subscription',
          canCreateSubscription: 'Can Create Subscription',
          canEditSubscription: 'Can Edit Subscription',
          canDeleteSubscription: 'Can delete Subscription'
        },
        marketingMessages: {
          orderInfo: 'Información de la orden',
          inKitchen:
            ', hola! Gracias por usar nuestro servicio. Su orden está en estos momentos en la cocina.',
          cooked: 'Su orden está lista y, luce genial!',
          delivering:
            'Su orden va de camino en estos momentos. Solo unos minutos más, por favor.',
          delivered: 'Su orden ha sido entregada. Bon appétit!',
          canceled: 'Su orden ha sido cancelada',
          cateringApproved:
            ', su orden de catering ha sido aprobada. Por favor, revise su email para ver los detalles de la orden.',
          cateringCancel:
            ', lo sentimos, su orden de catering ha sido cancelada.',
          reservationChangeStatus: 'Cambio de estado de su reservación',
          reservationApproved: ' , hola! Su reservación ha sido aprobada para ',
          reservationApproved2: ', ahora necesita confirmar la reservación.',
          reservationProposedDateTime:
            ' , hola! Lamentamos no poder cumplir con la fecha/hora solicitada por usted. Le proponemos esta nueva fecha/hora: ',
          reservationRejected:
            ' , hola! Lamentamos tener que rechazar su reservación por falta de capacidad.',
          reservationCancel:
            'Su reservación ha sido cancelada. Si tiene alguna inquietud no dude en ponerse en contacto con nosotros.'
        }
      }
    },
    frontend: {
      menu: {
        clockInMessage: 'Has registrado correctamente',
        signIn: 'Iniciar Sesión',
        chatImenu: 'Soporte iMenuApps®',
        aboutImenu: 'Sobre iMenuapps®',
        seeLess: 'Ver menos',
        seeMore: 'Ver más',
        foodAndMoodJournal: 'Diario de comida y estado de ánimo',
        motivation: `Para ver tu historial de pedidos descarga la aplicación iMenuApps® y regístrate.
             <a href="https://apps.apple.com/us/app/imenuapps/id1574771438" target="_blank">Descargar en Apple App Store</a> o 
             <a href="https://play.google.com/store/apps/details?id=net.imenuapps.apps&hl=en_US&pli=1" target="_blank">Obtenerlo en Google Play</a>.`,
        motivationMobile:
          'Para ver tu historial de pedidos, regístrate con iMenuApps®.',
        menu: 'Menú',
        shop: 'Shop',
        ads: 'Anuncios Digitales',
        credit: 'Créditos',
        menuCatering: 'Menú de Catering',
        pickupRequest: 'Solicitud de recogida',
        reservation: 'Reservaciones',
        about: 'Quiénes somos',
        oferts: 'Ofertas',
        contact: 'Contactanos',
        init: 'Inicio',
        loginMerchant: 'Inicio sesión comerciante',
        loginClient: 'Inicio sesión cliente',
        editProfile: 'Edita tu perfil',
        edit: 'Modificar sus datos',
        log: 'Sign In / Registrar',
        catering: 'Servicio de Catering',
        logout: 'Cerrar Sesión',
        logIn: 'Iniciar Sesión',
        profil: 'Su Cuenta',
        profil1: 'Perfil',
        createUser: 'Registrarse',
        forgotPassword: 'Olvidó su contraseña?',
        passwordReset: 'Restablecer contraseña',
        newPassword: 'Nueva Contraseña',
        newData: 'Modificar sus datos',
        confirmPassword: 'Confirmar Contraseña',
        register: 'Registrar',
        orders: 'Órdenes',
        restaurant: 'Seleccione el Tipo de Negocio abajo',
        restablacerPassword: 'Restablecer Contraseña',
        updateData: 'Actualizar Información',
        mssForgotPassword: 'Hemos mandado una nueva contraseña a su correo.',
        errorLogIn: 'Correo o contraseña incorrecto',
        errEmailExist: 'Esta email ya existe',
        errPassNotCoinc: 'No coincide con el nuevo password',
        userNotFound: 'Correo incorrecto',
        refresh: 'Refrescar',
        mssUpdateData: 'Sus datos se actualizaron correctamente',
        mssCreateUser:
          'Cliente creado correctamente, hemos enviado su nueva contraseña por correo.',
        mssPasswordChangedCorrectly: 'Contraseña actualizada correctamente',
        ticketMenu: 'Ticket Abierto'
      },
      app: {
        allBusiness: 'Todos los Negocios',
        hospitality: 'Hospitality',
        reservations: 'Reservaciones',
        service: 'Servicio',
        entertainment: 'Entretenimiento',
        retail: 'Minorista',
        fullText:
          'Presentamos iMenuApps®: su solución comercial integral con portal de cliente mejorado iMenuApps® presenta una plataforma integral diseñada para satisfacer todas las necesidades comerciales, revolucionando la forma en que opera y se conecta con sus clientes. Nuestra aplicación no solo permite a las empresas optimizar las operaciones y aumentar las ventas, sino que también incorpora un portal de clientes de vanguardia que mejora la experiencia del cliente. Con nuestro portal de clientes, los clientes pueden volver a pedir productos y servicios sin esfuerzo y mantener un registro completo de sus transacciones y perfiles con cada comerciante con el que interactúan en nuestra plataforma. Esta característica fomenta la conveniencia, la eficiencia y las interacciones personalizadas, lo que en última instancia fortalece el vínculo entre las empresas y sus valiosos clientes. Ya sea que opere en hotelería, comercio minorista, servicios o entretenimiento, iMenuApps® es la solución definitiva adaptada a los requisitos específicos de su industria. Nuestra plataforma avanzada integra a la perfección el portal del cliente, lo que permite a las empresas brindar una experiencia perfecta y personalizada a su clientela. Adopte iMenuApps® y abra un mundo de posibilidades para su negocio. Benefíciese de nuestra interfaz de usuario intuitiva, funcionalidad robusta e infraestructura segura, todo orientado a optimizar las operaciones y fomentar relaciones duraderas con los clientes.',
        thisPay: 'Este Pago Realizado',
        thisPayDone: 'Este pago ha sido ya realizado.',
        selectOrder: 'Seleccione su tipo de orden',
        selectDetail: 'Su información',
        deliver: 'Entrega',
        pickup: 'Recogida',
        table: 'Mesa',
        curbside: 'En Auto',
        notClient:
          'No existen ordenes asociadas a este cliente. Recuerde que solo las pedidos de clientes registrados se podrán consultar.',
        getOrder: 'Para consultar órdenes',
        editTitle: 'Modifique sus datos',
        notPermissionForRating:
          'Solo los usuarios registrados pueden calificar negocios',
        thanksForRating: 'Gracias por calificarnos',
        sessionExpired:
          'Su sesión ha caducado por inactividad. Inicie sesión de nuevo.',
        notRestaurantFound:
          'No existe el negocio solicitado. Esperemos encuentre uno de su preferencia en nuestro listado.',
        all: 'Todos',
        productsAndServices: 'Ventas',
        reservation: 'Reservas'
      },
      product: {
        title: 'Elementos de menú de ',
        back: 'Atrás',
        noProducts:
          'Upss! Lo sentimos, esta categoría aún no tiene elementos de menú. ',
        productDetail: 'Detalles del elemento de menú',
        massageToast: 'Operación satistactoria ',
        productNote: 'Inserte aquí notas del elemento de menú...',
        productWithVariant: 'Clic ADICIONAR para otras opciones'
      },
      home: {
        country: 'Pais',
        stateProvince: 'Estado/Provincia/Región',
        county: 'Condado',
        autocomplete:
          'Comience a ingresar su dirección aquí para completar automáticamente',
        selectCategory: 'Categorías del menú',
        cardTitle: 'Detalles del carrito',
        cardheader: 'Elementos de menú',
        cancel: 'Cancelar',
        acept: 'Ok',
        confirm: 'Confirmar',
        clear: 'Limpiar',
        checkout: 'Verificar',
        deliverDetail: 'Detalles de la entrega',
        address: 'Dirección',
        pickupDetail: 'Detalles de la recogida',
        curbsideDetail: 'Detalles de la recogida en auto',
        cateringDate: 'Fecha del catering',
        pickTime: 'Horario de recogida no disponible. ',
        tableQr: 'QR de su ubicación',
        notValidQr: 'Código QR no válido',
        notSeatAvailable: 'Este asiento ya está asignado',
        errorRequired: 'Inserte la información requerida',
        errorDateNotValid: 'Fecha no válida',
        noProductForReorder:
          'No hay disponiblidad de ningun elemento de menú de esta orden.',
        noProductAvailable:
          'Los siguientes elementos de menú no aparecen en la orden, por noestar disponibles el día de hoy.',
        clientRequired:
          'Acción solo permitida para usuarios registrados. Inicia sesión',
        cantNotValid: 'Cantidad de elementos de menú no es válida',
        zipCodeNotValid: 'ZipCode no válido.',
        notZoneToDeliver: 'Actualmente no entregamos en esta área.',
        state: 'Estado',
        city: 'Ciudad',
        notValidEmail: 'Email no válido',
        reqSuccess: 'Enviada exitosamente la solicitud de cambio!',
        approveSuccess: 'Approuvé avec succès la demande de modification!',
        alertDeleteData: 'Desea volver a empezar?',
        notTimeToPick: 'Horario de recogida entre: ',
        cookTime: '. El tiempo estimado de cocción es de ',
        priceEstimated: 'Estimado',
        minuts: ' minutos.',
        selectOrderType: 'Por favor elija su tipo de pedido',
        search: 'Buscar',
        aggregateFree: 'Incluidos Gratis',
        sides: 'Agregos',
        choises: 'Ingredientes',
        variants: 'Variantes',
        withoutIngredients: 'Sin opciones',
        eventType: 'Tipo de Evento',
        emailSended: 'Correo enviado',
        choseView: 'Opciones de Vistas',
        applicationCreateSuccess: 'Aplicación creada con éxito',
        applicationCreateError: 'Se produjo un error al crear la aplicación'
      },
      orderType: {
        order: 'Orden',
        invoice: 'Factura',
        quote: 'Cotización',
        email: 'Correo',
        password: 'Contraseña',
        emailPhone: 'Correo y Teléfono',
        name: 'Nombre y Apellidos',
        phone: 'Teléfono',
        address: 'Dirección',
        code: 'Código Zip',
        pickDate: 'Fecha de Recogida',
        pickOut: 'Hora de Recogida',
        licencePlate: 'Matrícula',
        vehicleModel: 'Marca / modelo del vehículo',
        vehicleColor: 'Color del vehículo',
        contactedBy: 'Contactar por',
        tellContact: 'Díganos cómo contactarlo mejor',
        worker: 'Trabajador',
        minAmountDelivery: 'Monto mínimo para Catering',
        minAmountPickUp: 'Monto mínimo para Catering de Recogida'
      },
      reservation: {
        availability: 'Hora de reservaciones semanales',
        reservations: 'Reservaciones',
        reservationDetail: 'Detalle de la Reservación',
        create: 'Nueva Reservación',
        reservationDate: 'Fecha de la Reservación',
        reservationHour: 'Hora de la Reservación',
        timezone: 'Timezone',
        peoples: 'Personas',
        serviceTime: 'Tiempo Estimado del Servicio',
        reason: 'Ocasión',
        find: 'Buscar Reservación',
        enterCode: 'Inserte el código',
        yoursReservations: 'Reservaciones',
        code: 'Código',
        completeDateReservacion: 'Fecha',
        notcodefind: 'No se encontró reservación asociada a este código',
        state0: 'Solicitada',
        state1: 'Aprobada',
        state2: 'Fecha / Hora Propuesta',
        state3: 'Rechazada',
        state4: 'Confirmada',
        state5: 'Cancelada',
        state6: 'Cerrada',
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miércoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Saturday: 'Sábado',
        Sunday: 'Domingo',
        all: 'Todas',
        restaurantHour: 'Horario del Negocio',
        open: 'Abierto',
        close: 'Cerrado Ahora, continúe en la página',
        errorHourCaterin: 'Horario de Catering de ',
        errorHour: 'Horario de reserva de ',
        errorHour2: ' es de ',
        discount: 'Descuento',
        orderCloseToDiscount:
          'Reservación cerrada, y no se puede usar el pago de Cotización de la misma.',
        subtotalNotForDiscount:
          'No se puede usar el pago de Cotización porque es mayor que el Subtotal.',
        notDiscountToApply: 'No existe descuento para aplicar.',
        restaurantNotes: 'Nota del Negocio',
        quotationMessage1: 'Nuestra tarifa de cena prepaga es de  ',
        quotationMessage2:
          'y esta cantidad se deducirá de su pedido si realiza un pedido con nosotros. ',
        dinnerPrePayment: 'Prepago de Cena',
        timeToReserve: 'Tiempo a reservar',
        available: 'Disponibilidad',
        notAvailable: 'No Disponibilidad',
        deposit: 'Depósito de Reservación',
        seatsAvailable: ' Asientos Disponibles',
        guestLocation: 'Seleccione la ubicación de todos sus invitados',
        notGuestAvaibility: 'No existe disponibilidad para todos sus invitados',
        selectStaff: 'Seleccionar un personal'
      },
      order: {
        item: 'Articulo',
        description: 'Descripción',
        quantity: 'Cantidad',
        taxable: '¿Impuestos?',
        amount: 'Monto',
        discount: 'Descuento',
        creditCardFee: 'Tarifa de tarjeta de crédito',
        shipping: 'Envío',
        tax: 'Impuesto',
        deposit: 'Depósito',
        pendingAcceptance: 'Aceptación pendiente',
        accepted: 'Aceptada',
        prepare: 'Preparada',
        email: 'E-mail',
        phone: 'Phone',
        goBack: 'Go Back',
        orderDetail: 'Detalles del Pedido',
        quoteDetail: 'Detalles de la Propuesta',
        convertInvoice: 'Convertir a Factura',
        aceptQuote: 'Aceptar Propuesta',
        productServicesUpdated: 'Productos / Servicios actualizados',
        payInvoive: 'Pagar Factura',
        invoiceDetail: 'Detalles de la Factura',
        invoiceNumber: 'No. Factura',
        invoiceTerm: 'Términos',
        invoicePayed: 'Su factura ha sido pagado',
        shippingDate: 'Fecha de envío',
        shippingVia: 'Vía de Entrega',
        shippingTo: 'Entrega Desde',
        shippingFrom: 'Entrega Hacia',
        shippingTrackingNumber: 'Número de Rastreo',
        client: 'Cliente',
        cart: 'Carrito',
        productsOrdered: 'Productos Ordenados',
        products: 'Elementos de menú',
        name: 'Nombre',
        cant: 'Cant',
        price: 'Precio',
        total: 'Total',
        otherCharges: 'Otros cargos',
        quotationPayment: 'Pago de cotización',
        cashPayment: 'Pago en Cash',
        isTicket: 'Abrir orden como ticket',
        payAsTicket: 'Autorizar pago',
        sendInvoice: 'Emviar factura ',
        saveAsTicket: 'Salvar Ticket',
        checkPayment: 'Pago en Cheque',
        parcialPayment: 'Pago Parcial',
        quotationMessage1: 'Nuestra tarifa de cotización es de ',
        quotationMessage2:
          'y esta cantidad se deducirá de su pedido si realiza un pedido con nosotros. ',
        pendingPayment: 'Pago Pendiente',
        subtotal: 'Subtotal',
        taxe: 'Impuestos',
        deliver: 'Entrega',
        tip: 'Propina',
        notes: 'Notas',
        payment: 'Detalles del pago',
        ccard: 'Tarjeta de Crédito',
        ccode: 'Código de la tarjeta',
        expcard: 'Fecha de Expiración',
        dueDate: 'Fecha de Vencimiento',
        pay: 'Pagar',
        verify: 'Verificar Pago',
        transictionTitle: 'Estado de la Transacción',
        transictionOk: 'Transacción realizada correctamente',
        sendRequest: 'Enviar solicitud',
        requestCatering:
          'Solicitud enviada. El administrador del negocio lo contactará próximamente.',
        transictionError: 'Error ejecutando el pago',
        notValidCC: 'Tarjeta de crédito no válida',
        yourOrders: 'Ordenes ',
        yourTicket: 'Tickets ',
        seeAll: 'Ver todas sus órdenes',
        orderState: 'Estado de la orden',
        cooker: ' Cocinero',
        type: 'Tipo',
        state: 'Estado',
        messageToast:
          'Para conocer los cambios de estado de su pedido, inserte su correo electrónico o teléfono ',
        messgForContact:
          'Para conocer los cambios de estados de su orden, inserte su Correo o Teléfono',
        messgForContactCatering:
          'Debe iniciar sesión para crear orden de Catering',
        date: 'Fecha',
        state0: 'Pago Pendiente',
        state1: 'Pagada',
        state2: 'En Cocina',
        state3: 'Preparada',
        state4: 'En camino',
        state5: 'Entregada',
        state6: 'Cancelada',
        state7: 'Pendiente a revisión',
        state8: 'Catering Aprobada',
        state9: 'Propuesta',
        state10: 'Pedido recogido',
        state11: 'Demorado',
        notOrder: 'No tiene órdenes pendientes',
        transId: 'Transacción',
        invoice: 'Factura',
        invoiceProposal: 'Factura Propuesta',
        invoiceProposalUpdated: 'Factura Propuesta Actualizada',
        add: 'Adicionar',
        share: 'Compartir',
        remove: 'Eliminar',
        edit: 'Editar',
        cancelReason: 'Motivo de cancelación',
        paymentMethod: 'Método de pago',
        orderFor: 'Orden para',
        other: 'Otro',
        payed: 'Pagado',
        toPay: 'Por Pagar',
        readyForPick:
          'Usted recibirá un email o un texto cuando su orden esté lista para recoger.',
        location: 'Ubicación',
        hourToPickEstimated: 'Hora estimada de recogida',
        dateToPickEstimated: 'Fecha estimada de recogida',
        routingNumber: 'Número de ruta bancaria',
        accountNumber: 'Número de cuenta',
        bankName: 'Nombre del banco',
        badroutingNumber: 'Número de ruta bancaria incorrecta',
        eventName: 'Nombre del Evento',
        guessNumber: 'Cantidad de Invitados',
        eventDate: 'Fecha del Evento',
        eventStartHour: 'Hora de Inicio del Evento',
        eventEndHour: 'Hora de Fin del Evento',
        warningProductsTheOrderToCatering:
          'Está intentando agregar un elemento de menú que NO es del Servicio de Catering. Si desea continuar perderá los elementos de menú agregados anteriormente.',
        warningProductsTheCateringToOrder:
          'Está intentando agregar un elementos de menú del Servicio de Catering. Si desea continuar perderá los elementos de menú agregados anteriormente.',
        clientInfo: 'Información del cliente',
        orderType: 'Tipo de Orden',
        paymentByCard: 'Pagar por Tarjeta de Crédito',
        totalForPay: 'Total a pagar',
        firstName: 'Nombre(s)',
        lastName: 'Apellido(s)',
        postalCode: 'Código Postal',
        addressLine1: 'Dirección',
        updateTicket: 'Actualizar Ticket',
        closeTicket: 'Cerrar Ticket',
        closed: 'Cerrado',
        requiredCustomer: 'Datos del Cliente',
        requiredOrderType: 'Tipo de Orden',
        culumnNumber: 'Numero de columnas de la fila'
      },
      tooltips: {
        addOrder: 'Adicionar',
        printOrder: 'Imprimir',
        forward: 'Reenviar',
        reOrder: 'Reordenar',
        refreshOrder: 'Refrescar',
        refreshCaptcha: 'Refrescar Captcha',
        deleteOrder: 'Eliminar',
        readyProduct: 'Listo',
        printRes: 'Imprimir',
        shareLocation: 'Compartir Ubicación',
        closeTicket: 'Cerrar',
        editTicket: 'Editar',
        loadInfo: 'Cargando sus datos',
        loadRestaurant: 'Cargando...',
        getToRestaurant: 'Click aqui si ha llegado al negocio',
        carInRestaurant: 'El auto llegó al negocio',
        carArrivedInfo: 'Gracias por dejarnos saber que su auto llego',
        errorRestaurantOffline: 'Este business no está visible para el público',
        deliveryPayment: 'Pago de entrega'
      },
      payment: {
        splitPayment: 'Dividir Pago',
        splitIn: 'Dividir en',
        splitMss:
          'Las cantidades a pagar deben cubir el monto Total de la Orden. Ningún pago puede ser 0',
        splitPay: 'Pago #',
        splitToPay: 'Pagar',
        splitPayed: 'Pagado',
        tjtPayment: 'Tarjeta',
        creditPayment: 'Crédito',
        devicePayment: 'Dispositivo',
        idtechPayment: 'Moto/Swipe',
        swipePayment: 'Swipe',
        cashPayment: 'Efectivo',
        hcaPayment: 'ACH',
        housePayment: 'Cuenta Casa',
        checkPayment: 'Cheque',
        sharePayment: 'Compartir Pago',
        qrPayment: 'Qr',
        verifyMss:
          'Luego de conocer que su pago ha sido realizado, Verique el pago para guardar la orden, o Cancelar para no continuar este pago.',
        doingPayment: 'Realizando el pago',
        authorizingPayment: 'Autorizando pago',
        insuficientFunds: 'Fondos insuficientes',
        quoteExpired: 'Lo sentimos. Ha expirado el tiempo de esta propuesta',
        quoteChanged: 'La propuesta se ha modificada, se encuentra en estado: ',
        quoteAcepted: 'La propuesta ha sido Aceptada',
        goodPayment: 'Pagado',
        shareText1:
          ' compartió un enlace de pago para el pedido de comida que realizó en nuestro negocio ',
        shareText1a:
          ' compartió un enlace de pago para la reservación que realizó en nuestro negocio   ',
        shareText2:
          '. Siga el enlace a continuación para realizar el pago. ¡Gracias!'
      },
      createNew: {
        businessTemplate: 'Plantilla del negocio',
        restaurantName: 'Nombre del negocio',
        restaurantAddres: 'Dirección del negocio',
        restaurantPhone: 'Teléfono del negocio',
        restaurantEmail: 'Correo del negocio',
        restaurantZipcode: 'Zipcode del negocio',
        restaurantWeb: 'Web del negocio',
        restaurantFax: 'Fax del negocio',
        merchantName: 'Nombre del comerciante',
        merchantLastName: 'Apellido del comerciante',
        merchantAddres: 'Dirección del comerciante',
        merchantZipcode: 'ZipCode',
        merchantPhone: 'Celular del comerciante',
        merchantEmail: 'Correo del comerciante',
        merchantClerk: 'Pin del usuario (1 - 99999)',
        createTitle: 'Crear nuevo negocio en iMenuApps®',
        dataRestaurant: 'Datos del negocio',
        dataMercahnt: 'Datos del comerciante',
        restaurants: 'Negocio',
        createdMessage:
          'El negocio se ha creado correctamente. Recibirá un correo con la contraseña para acceder a la aplicación',
        clerkToLong: 'Valor de Pin excedido, máximo permitido 99999',
        clerkNotValid: 'Valor de Pin no válido',
        reciveSubscription: 'Desea recibir notificaciones de ',
        activateSubscription: 'Desea activar las notificaciones de ',
        saving: 'Guardando negocio',
        recordSaving: 'Ahorro',
        active: 'Activo',
        inactive: 'No activo',
        restaurantType: 'Tipo de Negocio',
        template: 'Plantilla',
        chooseBusinessTemplate: 'Elija la plantilla de negocios',
        select: 'Seleccione',
        temrsAndCondition: 'Términos y Condiciones',
        trialDate: '14-días de prueba',
        trialMss: 'Probar por 14 días',
        business: 'Negocio',
        reservation: 'Reservación'
      },
      unsubscribe: {
        title: 'Darse de baja',
        email: 'Correo',
        unsubscribeOk: 'Su correo se ha dado de baja correctamente',
        unsubscribeFail: 'Error. Correo no encontrado',
        restaurantFail: 'Negocio no encontrado'
      },
      specialsPrice: {
        titles: 'Precios Especiales',
        title: 'Precio Especial',
        name: 'Nombre',
        type: 'Tipo',
        amount: 'Monto',
        for: 'Para',
        apply: 'Aplicar a',
        active: 'Activo',
        repite: 'Repetir',
        oneStarDate: 'Fecha y Hora de Inicio',
        oneEndDate: 'Fecha y Hora de Fin',
        weekStarDate: 'Hora de Inicio',
        weekEndDate: 'Hora de Fin',
        monthStardDat: 'Día del mes',
        annualStartDat: 'Mes y Día del año',
        activeRule:
          'Solo se aplicará una regla a la vez. El primero que se active tendrá prioridad'
      },
      credit: {
        state0: 'Solicitado',
        state1: 'Aceptado',
        state2: 'Cancelado',
        state3: 'Cerrado',
        yourCredit: 'Sus créditos',
        amount: 'Monto del crédito',
        debt: 'Deuda',
        createDate: 'Fecha de creación',
        active: 'Activo',
        dateFrom: 'Fecha inicio',
        dateTo: 'Fecha Fin',
        paymentDate: 'Fecha de pago',
        state: 'Estado',
        pendingPay: 'Pendiente de pago',
        payed: 'Pagado',
        creditpayed: 'Pago de Crédito',
        mssPay1: 'El cliente ',
        mssPay2: 'ha realizado un pago del crédito ',
        mssPay3: 'por un monto '
      }
    }
  }
}

export const i18n = new VueI18n({
  locale: window.navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages
})
