<template>
  <!--We did a change en interface="popover" for interface="alert"-->
    <ion-select interface="alert" @ionChange="changeLanguage($event.target.value)" :value="currentLanguage">
      <ion-select-option v-for="language in languages" :key="language.locale" :value="language.locale">
        {{ language.title }}
      </ion-select-option>
    </ion-select>
  </template>
  
  <script>
  import { i18n } from '@/plugins/i18n';
  
  export default {
    name: 'LanguageComponent',
    data() {
      return {
        currentLanguage: i18n.locale,
      };
    },
    computed: {
      languages() {
        return [
          { locale: 'es', title: 'Español' },
          { locale: 'en', title: 'English' },
        ];
      },
    },
    methods: {
      changeLanguage(locale) {
        this.currentLanguage = locale;
        i18n.locale = locale;
      },
    },
  };
  </script>
<!-- <template>
            
                <ion-select interface="popover"
                @ionChange="changeLanguage($event.target.value)" :value="currentLanguaje">
                    <ion-select-option v-for="language in languages" :key="language.locale" :value="language.locale" >{{language.locale}}</ion-select-option>
                </ion-select>
            

</template>

<script>
import { i18n } from '@/plugins/i18n'

export default{
    name: 'LanguageComponent',
    data: () => ({
        currentLanguaje: i18n.locale,
    }),
    computed: {
        languages() {
            return [
                { locale: 'es', title: this.$t('spanish') },
                { locale: 'en', title: this.$t('english') }
            ]
        }
    },
    methods: {
        changeLanguage(locale) {
            this.currentLanguaje = locale
            i18n.locale = locale
        }
    }
}
</script> -->


  