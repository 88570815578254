import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authenticated: false,
    user: null,
    roles: [],
    backConfig: {},
    // FRONTEND STATE
    addresses: [],
    customer: {},
    guess: {},
    allRestaurant: [],
    restaurantActive: {},
    allReservations: [],
    menuSinCatering: [],
    categoryMenuListSinCatering: [],
    categoryMenuConCatering: [],
    menuConCatering: [],
    productsManagement: [],
    transactionData: [],
    products: [],
    variants: [],
    categories: [],
    tax: {},
    allTaxes: [],
    shipping: {},
    cart: [],
    order: {},
    allOrders: [],
    allTickets: [],
    configuration: {},
    hasRating: false,
    device: {},
    staffName: "",
    staffId: "",
    restaurantId: "",
    walletConfig: {},
    subscribers: [],
    customerCredit: {},
    allCustomerCredit: [],
    staffHouseAccount: false,
    restaurantCustomers: [],
    allStaff: [],
    about: [],
    // terms: [],
    legalDocument: [],
    // privacyPolicy: [],
    // otherPrivacy: [],
    digitalSignage: [],
    // termsandCondition: [],
    isMerchant: false,
    footer: {},
    journalEntry: [],
  },
  mutations: {
    setAuthentication(state, status) {
      state.authenticated = status;
    },
    setUser(state, userl) {
      state.user = userl;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setBackConfig(state, backConfig) {
      state.backConfig = backConfig;
    },
    // FRONTEND MUTATION
    setCustomer(state, customer) {
      state.customer = customer;
      state.guess = customer;
    },
    setAddresses(state, addresses) {
      state.addresses = addresses;
    },
    setGuess(state, guess) {
      state.guess = guess;
    },
    setAllRestaurant(state, allRestaurant) {
      state.allRestaurant = allRestaurant;
    },
    setAllReservations(state, allReservations) {
      state.allReservations = allReservations;
    },
    setMenuSinCatering(state, menuSinCatering) {
      state.menuSinCatering = menuSinCatering;
    },
    setCategoryMenuListSinCatering(state, categoryMenuListSinCatering) {
      state.categoryMenuListSinCatering = categoryMenuListSinCatering;
    },
    setCategoryMenuConCatering(state, categoryMenuConCatering) {
      state.categoryMenuConCatering = categoryMenuConCatering;
    },
    setMenuConCatering(state, menuConCatering) {
      state.menuConCatering = menuConCatering;
    },
    setProducts(state, producst) {
      state.products = producst;
    },
    setTransactionData(state, transactionData){
      state.transactionData = transactionData;
    },
    setProductsManagement(state, productsManagement) {
      state.productsManagement = productsManagement;
    },
    setVariants(state, variants) {
      state.variants = variants;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setTax(state, tax) {
      state.tax = tax;
    },
    setShipping(state, shipping) {
      state.shipping = shipping;
    },
    setRestaurantActive(state, restaurantActive) {
      state.restaurantActive = restaurantActive;
    },
    setCart(state, cart) {
      state.cart = cart;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setAllOrder(state, allOrders) {
      state.allOrders = allOrders;
    },
    setAllTickets(state, allTickets) {
      state.allTickets = allTickets;
    },
    setConfiguration(state, configuration) {
      state.configuration = configuration;
    },
    setHasRating(state, hasRating) {
      state.hasRating = hasRating;
    },
    setDevice(state, device) {
      state.device = device;
    },
    setStaffId(state, staffId) {
      state.staffId = staffId;
    },
    setStaffName(state, staffName) {
      state.staffName = staffName;
    },
    setRestaurantId(state, restaurantId) {
      state.restaurantId = restaurantId;
    },
    setWalletConfig(state, walletConfig) {
      state.walletConfig = walletConfig;
    },
    setSubscribers(state, subscribers) {
      state.subscribers = subscribers;
    },
    setAllTaxes(state, allTaxes) {
      state.allTaxes = allTaxes;
    },
    setCustomerCredit(state, customerCredit) {
      state.customerCredit = customerCredit;
    },
    setAllCustomerCredit(state, allCustomerCredit) {
      state.allCustomerCredit = allCustomerCredit;
    },
    setStaffHouseAccount(state, staffHouseAccount) {
      state.staffHouseAccount = staffHouseAccount;
    },
    setRestaurantCustomers(state, restaurantCustomers) {
      state.restaurantCustomers = restaurantCustomers;
    },
    setAllStaff(state, allStaff) {
      state.allStaff = allStaff;
    },
    setAbout(state, about) {
      state.about = about;
    },
    setDigitalSignage(state, digitalSignage) {
      state.digitalSignage = digitalSignage;
    },
    // setTermsandCondition(state, termsandCondition) {
    //   state.termsandCondition = termsandCondition;
    // },
    // setTerms(state, terms) {
    //   state.terms = terms;
    // },
    setLegalDocument(state, legalDocument) {
      state.legalDocument = legalDocument;
    },
    // setPrivacyPolicy(state, privacyPolicy) {
    //   state.privacyPolicy = privacyPolicy;
    // },
    // setOtherPrivacy(state, otherPrivacy) {
    //   state.otherPrivacy = otherPrivacy;
    // },
    setIsMerchant(state, isMerchant) {
      state.isMerchant = isMerchant;
    },
    setjournalEntry(state, journalEntry) {
      state.journalEntry = journalEntry;
    },
    restartFront(state) {
      (state.cart = []),
        (state.order = {}),
        (state.allOrders = []),
        (state.allReservations = []),
        (state.configuration = {}),
        (state.allTickets = []),
        (state.customerCredit = {}),
        (state.allCustomerCredit = []),
        (state.menuSinCatering = []),
        (state.categoryMenuListSinCatering = []),
        (state.categoryMenuConCatering = []),
        (state.menuConCatering = []),
        (state.products = []),
        (state.transactionData = []),
        (state.productsManagement = []),
        (state.variants = []),
        (state.categories = []),
        (state.tax = {}),
        (state.shipping = {}),
        (state.addresses = []),
        (state.restaurantActive = {}),
        (state.walletConfig = {}),
        (state.subscribers = []),
        (state.allTaxes = []),
        (state.staffHouseAccount = false),
        (state.restaurantCustomers = []),
        (state.about = []),
        (state.digitalSignage = []),
        // (state.termsandCondition = []),
        // (state.terms = []),
        (state.legalDocument = []),
        // (state.privacyPolicy = []),
        // (state.otherPrivacy = []),
        (state.journalEntry = []),
        (state.isMerchant = false);
    },
    setFooter(state, footer) {
      state.footer = footer;
    },
  },
});

export default store;
