<template>
    <ion-footer v-if="show">
        <ion-note v-if="showMessage">
            <p style="margin: 10px">
            {{ footerTitle }}
        </p>
        </ion-note>
        <ion-toolbar>
            <ion-button :disabled="!enableSubmitButton" @click="submitButtonAction">Submit</ion-button>
        </ion-toolbar>
    </ion-footer>
</template>

<script>

export default {
    data() {
    return {
      footerTitle: '',
      show: true,
      showMessage: false,
      showSubmitButton: false,
      enableSubmitButton: false,
      status: 'active',
      buttonAction : () => {}
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    '$route'(to, from) {
      this.setFooterTitle(to.name);
    },
    '$store.state.footer'(a,b) {
        //console.log('$store.state.footer', a, b);
        this.showMessage = a.showMessage;
        this.status = a.status;
        this.enableSubmitButton = a.enableSubmitButton;
        this.buttonAction = b.submitButtonAction;
    }
  },
  created() {
    this.setFooterTitle(this.$route.name);
  },
  methods: {
    setFooterTitle(routeName) {
      //console.log('setFooterTitle', routeName);
      switch (routeName) {
        case 'VirtualTerminal':
            this.show = true;
          this.footerTitle = 'I (we) hereby authorize a payment ACH debit entry to my (our) Checking/Share Draft/CMA or Savings/Share Draft account. I (we) understand that if the debit entry under this authorization is returned for insufficient funds or otherwise dishonored, I (we) will promptly remit the payment due plus any fees due under my (our) account.';
          break;
        default:
            this.show = false;
          this.footerTitle = '';
      }
    },
    submitButtonAction() {
        this.buttonAction();
    }
  },
}

</script>