//import Moment from 'moment'
import { DateTime } from "luxon";
import store from "../../store/index";

export var Utils = {
  // getFormatedDate: function(date){
  //     var d = new Date(date);
  //     var day = d.getUTCDate();
  //     var month = d.getUTCMonth() + 1;
  //     var year = d.getUTCFullYear();
  //     return month + "-" + day + "-" + year;
  //   },

  // getFormatHour: function(date){
  //   const hour = Moment(date).format('MM-DD | hh:mm A');
  //   return hour;
  // },

  // getFormatHourDate: function(date){
  //   const hour = Moment(date).format('MM-DD-YYYY | hh:mm A');
  //   return hour;
  // },

  // getFormatOnlyHour: function(date){
  //   const hour = Moment(date).format('hh:mm A');
  //   return hour;
  // },

  getFormatedDate: function (_date) {
    var date = _date;
    if (!(_date instanceof Date)) {
      date = new Date(date);
    }
    var d = DateTime.fromJSDate(date);
    var day = d.day;
    var month = d.month;
    var year = d.year;
    return `${month}-${day}-${year}`;
  },

  getFormatHour: function (date) {
    const d = DateTime.fromJSDate(new Date(date));
    const hour = d.toFormat("MM-dd | hh:mm a");
    return hour;
  },

  getFormatHourDate: function (date) {
    const d = DateTime.fromJSDate(new Date(date));
    const hour = d.toFormat("MM-dd-yyyy | hh:mm a");
    return hour;
  },

  getFormatOnlyHour: function (date) {
    const d = DateTime.fromJSDate(new Date(date));
    const hour = d.toFormat("hh:mm a");
    return hour;
  },

  getFormatNumber: function (currencyCode, number) {
    new Intl.NumberFormat("en", { style: "currency", currency: currencyCode })
      .format(number)
      .toString();
  },

  getFormatPrice(price) {
    let currencyActive = "USD";
    if (store.state.restaurantActive.currency)
      currencyActive = store.state.restaurantActive.currency;
    const result = new Intl.NumberFormat("en", {
      style: "currency",
      currency: currencyActive,
    }).format(price);
    return result;
  },

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  },

  decimalAdjust: function (type, value, exp) {
    // Si exp es undefined o cero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // Si el valor no es un número o exp no es un entero...
    if (Number.isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
    // Shift back
    value = value.toString().split("e");
    return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
  },

  //returns the name of the day based on the locale
  getDayName(dateStr, locale = "en-US") {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  },

  //get 24-hr format
  get24HourStr: function (dateStr, locale = "en-US", tz = null) {
    let d = DateTime.fromISO(dateStr, "HH:mm");
    if (tz) {
      d = d.setZone(tz);
    }
    const str = d.setLocale(locale).toFormat("HH:mm");
    return str;
  },
  //get 12-hr format
  get12HourStr: function (dateStr, locale = "en-US", tz = null) {
    let d = DateTime.fromISO(dateStr);
    if (tz) {
        d = d.setZone(tz);
    }
    const str = d.setLocale(locale).toFormat("hh:mm a");
    return str;
},


  get24HourStrFromTimestamp: function (dateStr, locale = "en-US", tz = null) {
    //console.log('JQD: get24HourStrFromTimestamp', dateStr, locale, tz);
    let d = DateTime.fromJSDate(new Date(dateStr), "HH:mm");
    if (tz) {
      d = d.setZone(tz);
    }
    const str = d.setLocale(locale).toFormat("HH:mm");
    return str;
  },

  //get day format MM-dd-yyyy for display on emails
  getDateStr: function (dateStr, locale = "en-US") {
    const d = DateTime.fromJSDate(new Date(dateStr));
    const str = d.setLocale(locale).toFormat("MM-dd-yyyy");
    return str;
  },

  isBetweenDates: function (date, date1, date2) {
    //console.log('JQD isBetweenDates', date, date1, date2);

    const equalOrBeyondDate1 = date >= date1;
    const equalOrBeyondDate2 = date <= date2;
    if (equalOrBeyondDate1 && equalOrBeyondDate2) {
      return true;
    }

    return false;
  },

  getUserTimezoneStr() {
    // Get the user's current timezone offset in minutes
    var timezoneOffsetMinutes = new Date().getTimezoneOffset();

    // Convert the offset to hours and minutes
    var hoursOffset = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
    var minutesOffset = Math.abs(timezoneOffsetMinutes) % 60;

    // Determine if the offset is positive or negative
    var offsetSign = timezoneOffsetMinutes > 0 ? "-" : "+";

    // Create a string representation of the timezone
    var timezoneString =
      "GMT" +
      offsetSign +
      this.padZero(hoursOffset) +
      ":" +
      this.padZero(minutesOffset);
    return timezoneString;
  },

  // Helper function to pad single-digit numbers with a leading zero
  padZero(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  },

  /**
   * Snap the date to next 15-min interval
   * @param {Date} date
   * @returns
   */
  snapToNextInterval(date) {
    // Calculate the minutes to add to reach the next 15-minute interval
    var minutesToAdd = 15 - (date.getMinutes() % 15);
    if (minutesToAdd === 15) {
      minutesToAdd = 0;
    }

    // Set the seconds and milliseconds to zero
    date.setSeconds(0, 0);

    // Add the necessary minutes
    date.setMinutes(date.getMinutes() + minutesToAdd);

    return date;
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  getDateDisplayInLocalTime: function (dateStr, locale = "en-US") {
    const d = DateTime.fromJSDate(new Date(dateStr));
    const str = d.setLocale(locale).toFormat("MMM dd, yyyy hh:mm a");
    return str;
  },

  defaultStyles:
    ":root { -ion-color-primary: #3880ff ; " +
    "--ion-color-primary-rgb: 56,128,255; " +
    "--ion-color-primary-contrast: #ffffff; " +
    "--ion-color-primary-contrast-rgb: 255,255,255; " +
    "--ion-color-primary-shade: #3171e0; " +
    "--ion-color-primary-tint: #4c8dff; " +
    "--ion-color-secondary: #3dc2ff; " +
    "--ion-color-secondary-rgb: 61,194,255; " +
    "--ion-color-secondary-contrast: #ffffff; " +
    "--ion-color-secondary-contrast-rgb: 255,255,255; " +
    "--ion-color-secondary-shade: #36abe0; " +
    "--ion-color-secondary-tint: #50c8ff; " +
    "--ion-color-tertiary: #5260ff; " +
    "--ion-color-tertiary-rgb: 82,96,255; " +
    "--ion-color-tertiary-contrast: #ffffff; " +
    "--ion-color-tertiary-contrast-rgb: 255,255,255; " +
    "--ion-color-tertiary-shade: #4854e0; " +
    "--ion-color-tertiary-tint: #6370ff; " +
    "--ion-color-success: #2dd36f; " +
    "--ion-color-success-rgb: 45,211,111; " +
    "--ion-color-success-contrast: #ffffff; " +
    "--ion-color-success-contrast-rgb: 255,255,255; " +
    "--ion-color-success-shade: #28ba62; " +
    "--ion-color-success-tint: #42d77d; " +
    "--ion-color-danger: #eb445a; " +
    "--ion-color-danger-rgb: 235,68,90; " +
    "--ion-color-danger-contrast: #ffffff; " +
    "--ion-color-danger-contrast-rgb: 255,255,255; " +
    "--ion-color-danger-shade: #cf3c4f; " +
    "--ion-color-danger-tint: #ed576b; }",
};
